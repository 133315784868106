import { BASE_URL, getApiClient } from "./apiUtils"; // Import the API client

const apiClient = getApiClient();

// Register a new user
export const register = async (userData) => {
  const response = await apiClient.post("/auth/users/", userData);
  return response.data;
};

// Login user
export const login = async (credentials) => {
  const response = await apiClient.post("/auth/jwt/create/", credentials);
  return response.data;
};

// Logout user
export const logout = async (refreshToken) => {
  const response = await apiClient.post("/auth/jwt/logout/", {
    refresh_token: refreshToken,
  });
  return response.data;
};

// Request password reset
export const requestPasswordReset = async (email) => {
  const response = await apiClient.post("/auth/users/reset_password/", {
    email,
  });
  return response.data;
};

// Confirm password reset
export const confirmPasswordReset = async (uid, token, newPassword) => {
  const response = await apiClient.post("/auth/users/reset_password_confirm/", {
    uid,
    token,
    new_password: newPassword,
  });
  return response.data;
};

// Request username reset
export const requestUsernameReset = async (email) => {
  const response = await apiClient.post("/auth/users/reset_username/", {
    email,
  });
  return response.data;
};

// Confirm username reset
export const confirmUsernameReset = async (uid, token, newUsername) => {
  const response = await apiClient.post("/auth/users/reset_username_confirm/", {
    uid,
    token,
    new_username: newUsername,
  });
  return response.data;
};

// Activate user account
export const activateUser = async (uid, token) => {
  const response = await apiClient.post("/auth/users/activation/", {
    uid,
    token,
  });
  return response.data;
};

// Resend activation email
export const resendActivationEmail = async (email) => {
  const response = await apiClient.post("/auth/users/resend_activation/", {
    email,
  });
  return response.data;
};

// Change user password
export const changePassword = async (oldPassword, newPassword) => {
  const response = await apiClient.post("/auth/users/set_password/", {
    current_password: oldPassword,
    new_password: newPassword,
  });
  return response.data;
};

// Create user password (without requiring current password)
export const createPassword = async (newPassword) => {
  const response = await apiClient.post("/auth/users/set_password/", {
    new_password: newPassword,
  });
  return response.data;
};

// Fetch current user profile
export const getProfile = async () => {
  const response = await apiClient.get("/auth/users/me/");
  return response.data;
};

export const updateProfile = async (userData) => {
  const response = await apiClient.put("/auth/users/me/", userData);
  return response.data;
};

// Google Login
export const initiateGoogleLogin = async () => {
  try {
    const response = await apiClient.get(
      "/auth/o/google-oauth2/?redirect_uri=" +
        BASE_URL +
        "account/callback/google"
    );
    return response.data.authorization_url;
  } catch (error) {
    console.error("Failed to initiate Google login:", error);
    throw error;
  }
};

export const handleGoogleCallback = async (code, state) => {
  try {
    const response = await apiClient.post(
      `/auth/o/google-oauth2/?state=${encodeURIComponent(
        state
      )}&code=${encodeURIComponent(code)}"
      )}`
    );

    return response.data;
  } catch (error) {
    console.error("Failed to handle Google OAuth callback:", error);
    throw error;
  }
};

export const getLinkedSocialAccounts = async (userId) => {
  const response = await apiClient.get(`/auth/users/${userId}/social/`);
  return response.data;
};

export const deleteLinkedSocialAccount = async (userId, socialId) => {
  const response = await apiClient.delete(
    `/auth/users/${userId}/social/${socialId}/`
  );
  return response.data;
};
