import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Button, Table, Select, message } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import InviteUserModal from "./InviteUserModal";
import {
  fetchTeam,
  updateUserRole,
  removeUser,
  fetchPendingInvitations,
  removeInvitation,
} from "../actions/teamsActions";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const TeamUsersTab = ({ team }) => {
  const { t } = useTranslation("teams"); // Initialize translation with the 'teams' namespace
  const dispatch = useDispatch();
  const pendingInvitations = useSelector(
    (state) => state.teams.pendingInvitations
  );
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);

  const errorMapping = {
    "No OrganizationUser matches the given query.": "no_organization_user",
    "The organization's owner cannot be downgraded to a member.":
      "owner_cannot_be_downgraded",
    "Cannot delete organization owner before organization or transferring ownership.":
      "owner_cannot_be_deleted",
  };

  useEffect(() => {
    dispatch(fetchPendingInvitations(team.id));
  }, [dispatch, team.id]);

  const handleRoleChange = async (userId, isAdmin) => {
    try {
      await dispatch(updateUserRole(team.id, userId, { is_admin: isAdmin }));
      dispatch(fetchTeam(team.id));
      message.success(t("team-users.success.role_update"));
    } catch (error) {
      message.error(
        t("team-users.error.role_update", {
          error: t(
            `team-users.error.specific.${
              errorMapping[error.response.data.detail]
            }`
          ),
        })
      );
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await dispatch(removeUser(team.id, userId));
      message.success(t("team-users.success.remove_user"));
    } catch (error) {
      message.error(
        t("team-users.error.remove_user", { error: error.response.data.detail })
      );
    }
  };

  const handleDeleteInvitation = async (invitationId) => {
    try {
      await dispatch(removeInvitation(team.id, invitationId));
      message.success(t("team-users.success.remove_invitation"));
    } catch (error) {
      message.error(
        t("team-users.error.remove_invitation", {
          error: error.response.data.detail,
        })
      );
    }
  };

  const showInviteModal = () => {
    setIsInviteModalVisible(true);
  };

  const handleInviteModalClose = () => {
    setIsInviteModalVisible(false);
  };

  const userColumns = [
    {
      title: t("team-users.columns.name"),
      dataIndex: ["user", "first_name"],
      key: "first_name",
      render: (_, record) =>
        `${record.user.first_name} ${record.user.last_name}`,
    },
    {
      title: t("team-users.columns.email"),
      dataIndex: ["user", "email"],
      key: "email",
    },
    {
      title: t("team-users.columns.role"),
      dataIndex: "is_admin",
      key: "is_admin",
      render: (is_admin, record) => (
        <Select
          value={
            is_admin ? t("team-users.role.admin") : t("team-users.role.member")
          }
          onChange={(value) =>
            handleRoleChange(record.id, value === t("team-users.role.admin"))
          }
          popupMatchSelectWidth={false}
        >
          <Select.Option value={t("team-users.role.admin")}>
            {t("team-users.role.admin")}
          </Select.Option>
          <Select.Option value={t("team-users.role.member")}>
            {t("team-users.role.member")}
          </Select.Option>
        </Select>
      ),
    },
    {
      title: t("team-users.columns.action"),
      key: "action",
      render: (_, record) => (
        <Button
          type="link"
          danger
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteUser(record.id)}
        >
          {t("team-users.button.remove")}
        </Button>
      ),
    },
  ];

  const invitationColumns = [
    {
      title: t("team-users.invitations.columns.invitee_email"),
      dataIndex: "invitee_email",
      key: "invitee_email",
    },
    {
      title: t("team-users.invitations.columns.invited_by"),
      dataIndex: ["invited_by", "username"],
      key: "invited_by",
    },
    {
      title: t("team-users.invitations.columns.date_sent"),
      dataIndex: "created",
      key: "created",
      render: (created) => new Date(created).toLocaleDateString(),
    },
    {
      title: t("team-users.invitations.columns.action"),
      key: "action",
      render: (_, record) => (
        <Button
          type="link"
          danger
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteInvitation(record.id)}
        >
          {t("team-users.button.remove")}
        </Button>
      ),
    },
  ];

  return (
    <>
      <Card
        title={t("team-users.title")}
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={showInviteModal}
          >
            {t("team-users.button.invite")}
          </Button>
        }
      >
        <Table
          columns={userColumns}
          dataSource={team.users}
          pagination={false}
          scroll={{ x: "max-content" }}
        />
      </Card>
      {pendingInvitations.length > 0 && (
        <Card
          title={t("team-users.invitations.title")}
          style={{ marginTop: "20px" }}
        >
          <Table
            columns={invitationColumns}
            dataSource={pendingInvitations}
            pagination={false}
            scroll={{ x: "max-content" }}
          />
        </Card>
      )}
      <InviteUserModal
        isVisible={isInviteModalVisible}
        onClose={handleInviteModalClose}
        teamId={team.id}
      />
    </>
  );
};

export default TeamUsersTab;
