import React from "react";
import { Switch } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { toggleTheme, setUserToggled } from "../actions/layoutActions"; // Adjust the import path based on your folder structure
import { MoonOutlined, SunOutlined } from "@ant-design/icons";

const ThemeToggle = () => {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.layout.themeMode);

  const handleToggleTheme = () => {
    dispatch(toggleTheme());
    dispatch(setUserToggled(true));
  };

  return (
    <Switch
      checked={themeMode === "dark"}
      onChange={handleToggleTheme}
      checkedChildren={<MoonOutlined />}
      unCheckedChildren={<SunOutlined />}
    />
  );
};

export default ThemeToggle;
