import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Typography, message } from "antd";
import { requestUsernameReset } from "../api/authApi";
import { useTranslation } from "react-i18next";

const UsernameResetRequest = () => {
  const { t } = useTranslation("accounts");
  const [email, setEmail] = useState("");
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      await requestUsernameReset(email);
      message.success(t("username-reset-request.success"));
      form.resetFields();
    } catch (error) {
      message.error(t("username-reset-request.error"));
    }
  };

  return (
    <div className="username-reset-request-container">
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        {t("username-reset-request.title")}
      </Typography.Title>
      <Form
        form={form}
        name="reset_username_request"
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t("username-reset-request.label.email")}
          name="email"
          rules={[
            {
              required: true,
              message: t("username-reset-request.validation.email_required"),
            },
          ]}
        >
          <Input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {t("username-reset-request.button")}
          </Button>
        </Form.Item>
      </Form>
      <div style={{ marginTop: "10px", textAlign: "center" }}>
        <Link to="/account/login">
          {t("username-reset-request.link.login")}
        </Link>
      </div>
    </div>
  );
};

export default UsernameResetRequest;
