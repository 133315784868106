import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { List, Button, Typography, message } from "antd";
import {
  fetchLinkedSocialAccounts,
  removeLinkedSocialAccount,
} from "../actions/authActions";
import googleLogo from "../../../assets/google-logo.svg"; // Import the Google logo image
import { useTranslation } from "react-i18next";

const LinkedSocials = () => {
  const { t } = useTranslation("accounts");
  const dispatch = useDispatch();
  const { user, linkedSocials } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      dispatch(fetchLinkedSocialAccounts(user.id));
    }
  }, [dispatch, user]);

  const handleDelete = async (socialId) => {
    try {
      await dispatch(removeLinkedSocialAccount(user.id, socialId));
      message.success(t("linked-socials.success"));
    } catch (error) {
      message.error(t("linked-socials.error"));
    }
  };

  return (
    <div className="linked-socials-container">
      <Typography.Title level={2}>{t("linked-socials.title")}</Typography.Title>
      <List
        bordered
        dataSource={linkedSocials}
        renderItem={(social) => (
          <List.Item
            actions={[
              <Button
                type="danger"
                onClick={() => handleDelete(social.id)}
                key="delete"
              >
                {t("linked-socials.button.remove")}
              </Button>,
            ]}
          >
            <List.Item.Meta
              avatar={
                social.provider.toLowerCase().includes("google") && (
                  <img
                    src={googleLogo}
                    alt="Google"
                    style={{ height: "24px", marginRight: "10px" }}
                  />
                )
              }
              title={social.provider}
              description={t("linked-socials.description", { uid: social.uid })}
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default LinkedSocials;
