const initialState = {
  notifications: [],
  loading: false,
  error: null,
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case "NOTIFICATIONS_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "NOTIFICATIONS_LOADED":
      return {
        ...state,
        notifications: action.payload,
        loading: false,
      };
    case "NOTIFICATIONS_LOAD_FAILURE":
    case "NOTIFICATION_OPERATION_FAILURE":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case "NOTIFICATION_MARK_AS_READ":
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          notification.id === action.payload
            ? { ...notification, is_read: true }
            : notification
        ),
      };
    case "NOTIFICATION_MARK_AS_UNREAD":
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          notification.id === action.payload
            ? { ...notification, is_read: false }
            : notification
        ),
      };
    case "NOTIFICATION_REMOVED":
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.payload
        ),
      };
    default:
      return state;
  }
}
