import {
  fetchNotifications,
  markNotificationAsRead,
  markNotificationAsUnread,
  deleteNotification,
  markAllNotificationsAsRead,
  markAllNotificationsAsUnread,
  deleteAllNotifications,
} from "../api/notificationsApi";

// Load Notifications
export const loadNotifications = () => async (dispatch) => {
  dispatch({ type: "NOTIFICATIONS_LOADING" });

  try {
    const data = await fetchNotifications();
    dispatch({
      type: "NOTIFICATIONS_LOADED",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "NOTIFICATIONS_LOAD_FAILURE",
      error: error.message,
    });
  }
};

// Mark a Notification as Read
export const readNotification = (notificationId) => async (dispatch) => {
  try {
    await markNotificationAsRead(notificationId);
    dispatch({
      type: "NOTIFICATION_MARK_AS_READ",
      payload: notificationId,
    });
  } catch (error) {
    dispatch({
      type: "NOTIFICATION_OPERATION_FAILURE",
      error: error.message,
    });
  }
};

// Mark a Notification as Unread
export const unreadNotification = (notificationId) => async (dispatch) => {
  try {
    await markNotificationAsUnread(notificationId);
    dispatch({
      type: "NOTIFICATION_MARK_AS_UNREAD",
      payload: notificationId,
    });
  } catch (error) {
    dispatch({
      type: "NOTIFICATION_OPERATION_FAILURE",
      error: error.message,
    });
  }
};

// Delete a Notification
export const removeNotification = (notificationId) => async (dispatch) => {
  try {
    await deleteNotification(notificationId);
    dispatch({
      type: "NOTIFICATION_REMOVED",
      payload: notificationId,
    });
  } catch (error) {
    dispatch({
      type: "NOTIFICATION_OPERATION_FAILURE",
      error: error.message,
    });
  }
};

export const markAllAsRead = () => async (dispatch) => {
  try {
    await markAllNotificationsAsRead();
    dispatch(loadNotifications()); // Reload notifications
  } catch (error) {
    dispatch({
      type: "NOTIFICATIONS_OPERATION_FAILURE",
      error: error.message,
    });
  }
};

export const markAllAsUnread = () => async (dispatch) => {
  try {
    await markAllNotificationsAsUnread();
    dispatch(loadNotifications()); // Reload notifications
  } catch (error) {
    dispatch({
      type: "NOTIFICATIONS_OPERATION_FAILURE",
      error: error.message,
    });
  }
};

export const deleteAll = () => async (dispatch) => {
  try {
    await deleteAllNotifications();
    dispatch(loadNotifications()); // Reload notifications
  } catch (error) {
    dispatch({
      type: "NOTIFICATIONS_OPERATION_FAILURE",
      error: error.message,
    });
  }
};
