import React, { useState } from "react";
import { Select } from "antd";
import i18n from "../../../i18n";
import { useResponsive } from "../hooks/useResponsive";
import { CircleFlag } from "react-circle-flags";

const { Option } = Select;

const LanguageSelector = () => {
  const { mdUp } = useResponsive();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value);
    localStorage.setItem("i18nextLng", value);
    setSelectedLanguage(value);
  };

  return (
    <Select
      className="language-selector"
      value={selectedLanguage}
      onChange={handleLanguageChange}
      style={{ width: mdUp ? "145px" : "64px", height: "40px" }}
    >
      <Option value="en">
        <div className="language-option">
          <CircleFlag countryCode="uk" height="24" />
          {mdUp && <span>English</span>}
        </div>
      </Option>
      <Option value="nl">
        <div className="language-option">
          <CircleFlag countryCode="nl" height="24" />
          {mdUp && <span>Nederlands</span>}
        </div>
      </Option>
    </Select>
  );
};

export default LanguageSelector;
