import React from "react";
import { Route, Routes } from "react-router-dom";

import PublicLayout from "../../layout/components/PublicLayout";
import PrivateLayout from "../../layout/components/PrivateLayout";
import ProtectedRoute from "../../accounts/routers/ProtectedRoute";

import Dashboard from "../components/Dashboard"; // Adjust the path if necessary

const DashboardRouter = () => {
  return (
    <Routes>
      {/* Public routes */}
      <Route element={<PublicLayout />}></Route>

      {/* Protected routes */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <PrivateLayout />
          </ProtectedRoute>
        }
      >
        <Route index element={<Dashboard />} />
        {/* Add more protected routes here */}
      </Route>
    </Routes>
  );
};

export default DashboardRouter;
