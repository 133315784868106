import React from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  Typography,
  Button,
  Table,
  Tag,
  Divider,
  Row,
  Col,
  List,
  Radio,
  Space,
} from "antd";
import {
  FilePdfOutlined,
  CreditCardOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const TeamBillingTab = ({ teamId }) => {
  const { t } = useTranslation("teams");

  const paymentMethods = [
    {
      key: "visa",
      icon: (
        <CreditCardOutlined style={{ color: "#1890ff", marginRight: "8px" }} />
      ),
      card: "Visa **** 9373",
      expiry: "Expiry 10/2024",
    },
    {
      key: "mastercard",
      icon: (
        <CreditCardOutlined style={{ color: "#ff4d4f", marginRight: "8px" }} />
      ),
      card: "Mastercard **** 2323",
      expiry: "Expiry 3/2023",
    },
  ];

  const paymentHistory = [
    {
      key: "1",
      amount: "$65.00",
      status: "Completed",
      recipient: "Gabriel Banks",
      date: "May 10, 2020",
      paymentMethod: "Visa 5432",
      avatar: "https://i.pravatar.cc/150?img=3",
      plan: "Company Start",
      invoiceDate: "Apr 10, 2020",
      idNumber: "EKG2SJFN",
      datePaid: "Apr 10, 2020",
      amountDue: "$250.00",
    },
    {
      key: "2",
      amount: "$250.00",
      status: "Completed",
      recipient: "Claudia Welch",
      date: "Apr 10, 2020",
      paymentMethod: "Visa 5432",
      avatar: "https://i.pravatar.cc/150?img=4",
      plan: "Company Start",
      invoiceDate: "Apr 10, 2020",
      idNumber: "EKG2SJFN",
      datePaid: "Apr 10, 2020",
      amountDue: "$250.00",
    },
  ];

  const columns = [
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => <Tag color="success">{text}</Tag>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (text, record) => (
        <>
          <CreditCardOutlined style={{ marginRight: 8 }} />
          {text}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          type="text"
          icon={<FilePdfOutlined style={{ color: "#ff4d4f" }} />}
          style={{ marginLeft: "auto" }}
        >
          Download PDF
        </Button>
      ),
    },
  ];

  return (
    <div className="team-billing-tab">
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Card className="team-billing-card" style={{ height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div style={{ flexGrow: 1, marginBottom: 16 }}>
                  <Typography.Text type="secondary">
                    Invoice Settings
                  </Typography.Text>
                  <Typography.Title level={4}>Maikel Baars</Typography.Title>
                  <Row>
                    <Col xs={24} md={12}>
                      <Typography.Text strong>
                        Seoripple BV
                        <br />
                        m.baars@seoripple.com
                      </Typography.Text>
                    </Col>
                    <Col xs={24} md={12}>
                      <Typography.Text strong>
                        Street 1<br />
                        1234 AB City
                        <br />
                        The Netherlands
                      </Typography.Text>
                    </Col>
                  </Row>
                </div>

                <div style={{ marginTop: "auto" }}>
                  <Button type="default">Change Invoice Settings</Button>
                </div>
              </div>
            </Card>
          </Col>
          <Col className="team-billing-card" xs={24} md={12}>
            <Card style={{ height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div style={{ flexGrow: 1, marginBottom: 16 }}>
                  <Typography.Text type="secondary">
                    Next payment
                  </Typography.Text>
                  <Typography.Title level={4}>$50.00</Typography.Title>
                  <Typography.Title level={5}>on May 15, 2020</Typography.Title>
                </div>

                <div style={{ marginTop: "auto" }}>
                  <Button type="default">Manage Payments</Button>
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        <Card title="Payment History">
          <Table
            columns={columns}
            dataSource={paymentHistory}
            scroll={{ x: "max-content" }}
            expandable={{
              expandedRowRender: (record) => (
                <Row gutter={16}>
                  <Col xs={24} md={18}>
                    <Row gutter={16}>
                      <Col xs={24} md={12}>
                        <Typography.Text type="secondary">
                          Status
                        </Typography.Text>
                        <Typography.Text style={{ display: "block" }}>
                          {record.status}
                        </Typography.Text>
                      </Col>
                      <Col xs={24} md={12}>
                        <Typography.Text type="secondary">
                          Invoice date
                        </Typography.Text>
                        <Typography.Text style={{ display: "block" }}>
                          {record.invoiceDate}
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} md={12}>
                        <Typography.Text type="secondary">
                          ID number
                        </Typography.Text>
                        <Typography.Text style={{ display: "block" }}>
                          {record.idNumber}
                        </Typography.Text>
                      </Col>
                      <Col xs={24} md={12}>
                        <Typography.Text type="secondary">
                          Date paid
                        </Typography.Text>
                        <Typography.Text style={{ display: "block" }}>
                          {record.datePaid}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={6}>
                    <Typography.Text type="secondary">
                      Amount due
                    </Typography.Text>
                    <Typography.Title level={4} style={{ margin: 0 }}>
                      {record.amountDue}
                    </Typography.Title>
                  </Col>
                </Row>
              ),
            }}
            pagination={false} // Removes pagination for a cleaner look
          />
        </Card>

        <Card title="Payment Methods">
          <List
            itemLayout="horizontal"
            dataSource={paymentMethods}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button type="link" key="list-loadmore-edit">
                    ...
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={item.icon}
                  title={<Radio value={item.key}>{item.card}</Radio>}
                  description={
                    <Typography.Text type="secondary">
                      {item.expiry}
                    </Typography.Text>
                  }
                />
              </List.Item>
            )}
          />
          <Button block icon={<PlusOutlined />} style={{ marginTop: "20px" }}>
            New payment method
          </Button>
        </Card>
      </Space>
    </div>
  );
};

export default TeamBillingTab;
