import React from "react";
import { Button, Typography } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation("pages");

  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      <Typography.Title level={2}>{t("title")}</Typography.Title>
      <Typography.Text>{t("description")}</Typography.Text>
      <Button type="primary">
        <Link to="/">{t("button")}</Link>
      </Button>
    </div>
  );
};

export default NotFound;
