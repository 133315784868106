import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { googleOAuthCallback } from "../actions/authActions";

const GoogleCallback = () => {
  const { t } = useTranslation("accounts");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isRequestingRef = useRef(false); // Track if a request is in progress

  useEffect(() => {
    const processOAuthCallback = async () => {
      const params = new URLSearchParams(window.location.search);
      const code = params.get("code");
      const state = params.get("state");
      const error = params.get("error");

      if (error) {
        handleOAuthError(error);
        return;
      }

      if (code && state && !isRequestingRef.current) {
        isRequestingRef.current = true; // Mark request as in progress
        try {
          await dispatch(googleOAuthCallback(code, state));
          message.success(t("google-callback.success"));
          navigate("/dashboard/");
        } catch (error) {
          message.error(t("google-callback.error.generic"));
          navigate("/account/login");
        } finally {
          isRequestingRef.current = false; // Reset flag after request completes
        }
      }
    };

    const handleOAuthError = (error) => {
      switch (error) {
        case "access_denied":
          message.error(t("google-callback.error.specific.access_denied"));
          break;
        default:
          message.error(t("google-callback.error.generic"));
      }
      navigate("/account/login");
    };

    processOAuthCallback();
  }, [navigate, dispatch, t]);

  return null;
};

export default GoogleCallback;
