import React from "react";
import { Card, Typography } from "antd";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import NoSubscription from "./NoSubscription";
import SubscriptionDetails from "./SubscriptionDetails";

const TeamSubscriptionTab = ({ teamId }) => {
  const { t } = useTranslation("teams"); // Using the 'teams' namespace

  const hasSubscription = true;

  if (!hasSubscription) {
    return <NoSubscription />;
  } else {
    return <SubscriptionDetails />;
  }

  return (
    <>
      <Card title={t("team-subscription.title")}>
        <Typography.Paragraph>
          {t("team-subscription.text")}
        </Typography.Paragraph>
      </Card>
    </>
  );
};

export default TeamSubscriptionTab;
