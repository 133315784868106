import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BellOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Badge, Dropdown, List, Spin, Button, Divider, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { formatDistanceToNow, format } from "date-fns";
import {
  loadNotifications,
  readNotification,
} from "../../notifications/actions/notificationsActions";
import Typography from "antd/es/typography/Typography";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

const NotificationCenter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notifications, loading } = useSelector(
    (state) => state.notifications
  );
  const [visible, setVisible] = useState(false);
  const [selectedType, setSelectedType] = useState("all");

  const { t } = useTranslation("notifications");

  useEffect(() => {
    dispatch(loadNotifications());
  }, [dispatch]);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const handleMarkAsRead = (id) => {
    dispatch(readNotification(id));
  };

  const handleGoToInbox = () => {
    navigate("/notifications/");
  };

  const unreadNotifications = notifications.filter((notif) => !notif.is_read);
  const unreadCount = unreadNotifications.length;

  const notificationTypes = Array.from(
    new Set(unreadNotifications.map((notif) => notif.type))
  );

  const filteredNotifications =
    selectedType === "all"
      ? unreadNotifications
      : unreadNotifications.filter((notif) => notif.type === selectedType);

  const getNotificationIcon = (type) => {
    const iconStyles = {
      fontSize: "18px",
      verticalAlign: "middle",
      marginRight: "8px",
      marginTop: "6px",
    };
    switch (type) {
      case "info":
        return (
          <InfoCircleOutlined style={{ ...iconStyles, color: "#1890ff" }} />
        );
      case "success":
        return (
          <CheckCircleOutlined style={{ ...iconStyles, color: "#52c41a" }} />
        );
      case "warning":
        return (
          <ExclamationCircleOutlined
            style={{ ...iconStyles, color: "#faad14" }}
          />
        );
      case "error":
        return (
          <CloseCircleOutlined style={{ ...iconStyles, color: "#f5222d" }} />
        );
      default:
        return null;
    }
  };

  const formatNotificationTime = (timestamp) => {
    const now = new Date();
    const diffInMinutes = (now - new Date(timestamp)) / 1000 / 60;

    if (diffInMinutes < 60) {
      return `${Math.floor(diffInMinutes)} minutes ago`;
    } else if (diffInMinutes < 720) {
      return `${Math.floor(diffInMinutes / 60)} hour${
        Math.floor(diffInMinutes / 60) > 1 ? "s" : ""
      } ago`;
    } else {
      return format(new Date(timestamp), "dd/MM/yyyy HH:mm");
    }
  };

  const handleTabChange = (key) => {
    setSelectedType(key);
  };

  const notificationMenu = (
    <div className="notification-dropdown" style={{ width: 400 }}>
      {loading ? (
        <Spin />
      ) : unreadNotifications.length > 0 ? (
        <>
          <div>
            <Tabs activeKey={selectedType} onChange={handleTabChange} centered>
              <TabPane tab={t("center.tab.all")} key="all" />
              {notificationTypes.map((type) => (
                <TabPane tab={t(`center.tab.${type}`)} key={type} />
              ))}
            </Tabs>
          </div>
          <div>
            <List
              dataSource={filteredNotifications}
              renderItem={(item) => (
                <List.Item
                  className={!item.is_read ? "unread" : ""}
                  actions={[
                    !item.is_read && (
                      <Button
                        onClick={() => handleMarkAsRead(item.id)}
                        key="mark-read"
                      >
                        {t("center.button.mark_as_read")}
                      </Button>
                    ),
                  ]}
                >
                  <List.Item.Meta
                    avatar={getNotificationIcon(item.type)}
                    title={item.message}
                    description={formatNotificationTime(item.created_at)}
                  />
                </List.Item>
              )}
            />
            <Divider style={{ margin: 0 }} />
            <Button
              type="link"
              onClick={handleGoToInbox}
              style={{
                display: "block",
                width: "100%",
                textAlign: "center",
                padding: "8px 0",
                marginTop: "4px",
                marginBottom: "8px",
              }}
            >
              {t("center.button.view_all")}
            </Button>
          </div>
        </>
      ) : (
        <div style={{ textAlign: "center", padding: "10px 0" }}>
          <Typography.Paragraph style={{ padding: "10px 0" }}>
            {t("center.no_notifications")}
          </Typography.Paragraph>
          <Divider style={{ margin: 0 }} />
          <Button
            type="link"
            onClick={handleGoToInbox}
            style={{
              display: "block",
              width: "100%",
              textAlign: "center",
              marginTop: 8,
            }}
          >
            {t("center.button.view_archive")}
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <div style={{ marginRight: 16 }}>
      <Dropdown
        overlay={notificationMenu}
        trigger={["click"]}
        onVisibleChange={handleVisibleChange}
        visible={visible}
        placement="bottomCenter"
      >
        <Badge count={unreadCount} offset={[5, 5]} showZero>
          <BellOutlined style={{ fontSize: 20, cursor: "pointer" }} />
        </Badge>
      </Dropdown>
    </div>
  );
};

export default NotificationCenter;
