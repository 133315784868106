import { getApiClient } from "../../accounts/api/apiUtils";

const apiClient = getApiClient();

export const getTeams = async () => {
  const response = await apiClient.get("/organizations/");
  return response.data;
};

export const getTeamById = async (id) => {
  const response = await apiClient.get(`/organizations/${id}/`);
  return response.data;
};

export const createTeam = async (teamData) => {
  const response = await apiClient.post("/organizations/", teamData);
  return response.data;
};

export const updateTeam = async (teamId, teamData) => {
  let config = {};
  if (teamData instanceof FormData) {
    config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }

  const response = await apiClient.patch(
    `/organizations/${teamId}/`,
    teamData,
    config
  );
  return response.data;
};

export const deleteTeam = async (teamId) => {
  const response = await apiClient.delete(`/organizations/${teamId}/`);
  return response.data;
};

export const inviteUserToTeam = async (teamId, email) => {
  const response = await apiClient.post(
    `/organizations/${teamId}/invitations/`,
    { email }
  );
  return response.data;
};

export const updateUserRoleInTeam = async (teamId, userId, roleData) => {
  const response = await apiClient.patch(
    `/organizations/${teamId}/users/${userId}/`,
    roleData
  );
  return response.data;
};

export const deleteUserFromTeam = async (teamId, userId) => {
  const response = await apiClient.delete(
    `/organizations/${teamId}/users/${userId}/`
  );
  return response.data;
};

export const fetchInvitation = async (organizationId, guid) => {
  const response = await apiClient.get(
    `/organizations/${organizationId}/invitations/accept/${guid}/`
  );
  return response.data;
};

export const acceptInvitation = async (organizationId, guid, values) => {
  const response = await apiClient.put(
    `/organizations/${organizationId}/invitations/accept/${guid}/`,
    values
  );
  return response.data;
};

export const getPendingInvitations = async (teamId) => {
  const response = await apiClient.get(`/organizations/${teamId}/invitations/`);
  return response.data;
};

export const deleteInvitation = async (organizationId, id) => {
  const response = await apiClient.delete(
    `/organizations/${organizationId}/invitations/${id}/`
  );
  return response.data;
};
