import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { List, Button, Typography, Row, Col, message } from "antd";
import {
  CheckSquareOutlined,
  UndoOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { format } from "date-fns";
import {
  loadNotifications,
  readNotification,
  unreadNotification,
  removeNotification,
  markAllAsRead,
  markAllAsUnread,
  deleteAll,
} from "../actions/notificationsActions";
import { useTranslation } from "react-i18next";

const NotificationsInbox = () => {
  const dispatch = useDispatch();
  const { notifications, loading, error } = useSelector(
    (state) => state.notifications
  );
  const { t } = useTranslation("notifications");

  useEffect(() => {
    dispatch(loadNotifications());
  }, [dispatch]);

  const handleMarkAsRead = (id) => {
    dispatch(readNotification(id))
      .then(() => {
        message.success(t("inbox.success.marked_as_read"));
      })
      .catch((error) => {
        message.error(
          t("inbox.error.mark_as_read", { message: error.message })
        );
      });
  };

  const handleMarkAsUnread = (id) => {
    dispatch(unreadNotification(id))
      .then(() => {
        message.success(t("inbox.success.marked_as_unread"));
      })
      .catch((error) => {
        message.error(
          t("inbox.error.mark_as_unread", { message: error.message })
        );
      });
  };

  const handleDelete = (id) => {
    dispatch(removeNotification(id))
      .then(() => {
        message.success(t("inbox.success.delete"));
      })
      .catch((error) => {
        message.error(t("inbox.error.delete", { message: error.message }));
      });
  };

  const handleMarkAllAsRead = () => {
    dispatch(markAllAsRead())
      .then(() => {
        message.success(t("inbox.success.marked_all_as_read"));
      })
      .catch((error) => {
        message.error(
          t("inbox.error.mark_all_as_read", {
            message: error.message,
          })
        );
      });
  };

  const handleMarkAllAsUnread = () => {
    dispatch(markAllAsUnread())
      .then(() => {
        message.success(t("inbox.success.marked_all_as_unread"));
      })
      .catch((error) => {
        message.error(
          t("inbox.error.mark_all_as_unread", {
            message: error.message,
          })
        );
      });
  };

  const handleDeleteAll = () => {
    dispatch(deleteAll())
      .then(() => {
        message.success(t("inbox.success.delete_all"));
      })
      .catch((error) => {
        message.error(t("inbox.error.delete_all", { message: error.message }));
      });
  };

  const getNotificationIcon = (type) => {
    const iconStyles = { fontSize: "18px", verticalAlign: "middle" };
    switch (type) {
      case "info":
        return (
          <InfoCircleOutlined style={{ ...iconStyles, color: "#1890ff" }} />
        );
      case "success":
        return (
          <CheckCircleOutlined style={{ ...iconStyles, color: "#52c41a" }} />
        );
      case "warning":
        return (
          <ExclamationCircleOutlined
            style={{ ...iconStyles, color: "#faad14" }}
          />
        );
      case "error":
        return (
          <CloseCircleOutlined style={{ ...iconStyles, color: "#f5222d" }} />
        );
      default:
        return null;
    }
  };

  const formatNotificationTime = (timestamp) => {
    const now = new Date();
    const diffInMinutes = (now - new Date(timestamp)) / 1000 / 60;

    if (diffInMinutes < 60) {
      return `${Math.floor(diffInMinutes)} minutes ago`;
    } else if (diffInMinutes < 720) {
      return `${Math.floor(diffInMinutes / 60)} hour${
        Math.floor(diffInMinutes / 60) > 1 ? "s" : ""
      } ago`;
    } else {
      return format(new Date(timestamp), "dd/MM/yyyy HH:mm");
    }
  };

  if (loading) return <Typography.Text>{t("inbox.loading")}</Typography.Text>;
  if (error)
    return (
      <Typography.Text type="danger">
        {t("inbox.error.generic", { message: error })}
      </Typography.Text>
    );

  return (
    <div className="notification-inbox-container">
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "20px" }}
      >
        <Col>
          <Typography.Title level={2}>{t("inbox.title")}</Typography.Title>
        </Col>
        <Col>
          <Button onClick={handleMarkAllAsRead} icon={<CheckSquareOutlined />}>
            {t("inbox.button.mark_all_as_read")}
          </Button>
          <Button
            onClick={handleMarkAllAsUnread}
            icon={<UndoOutlined />}
            style={{ marginLeft: 8 }}
          >
            {t("inbox.button.mark_all_as_unread")}
          </Button>
          <Button
            danger
            onClick={handleDeleteAll}
            icon={<DeleteOutlined />}
            style={{ marginLeft: 8 }}
          >
            {t("inbox.button.delete_all")}
          </Button>
        </Col>
      </Row>

      <List
        bordered
        dataSource={notifications}
        renderItem={(notification) => (
          <List.Item
            actions={[
              notification.is_read ? (
                <Button
                  onClick={() => handleMarkAsUnread(notification.id)}
                  key="mark-as-unread"
                >
                  {t("inbox.button.mark_as_unread")}
                </Button>
              ) : (
                <Button
                  onClick={() => handleMarkAsRead(notification.id)}
                  key="mark-as-read"
                >
                  {t("inbox.button.mark_as_read")}
                </Button>
              ),
              <Button
                danger
                onClick={() => handleDelete(notification.id)}
                key="delete"
              >
                {t("inbox.button.delete")}
              </Button>,
            ]}
          >
            <List.Item.Meta
              avatar={getNotificationIcon(notification.type)}
              title={notification.message}
              description={formatNotificationTime(notification.created_at)}
              style={{ display: "flex", alignItems: "center" }}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default NotificationsInbox;
