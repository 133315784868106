export const TOGGLE_THEME = "TOGGLE_THEME";
export const SET_THEME_SETTINGS = "SET_THEME_SETTINGS";
export const SET_USER_TOGGLED = "SET_USER_TOGGLED";

export const toggleTheme = () => ({
  type: TOGGLE_THEME,
});

export const setThemeSettings = (settings) => ({
  type: SET_THEME_SETTINGS,
  payload: settings,
});

export const setUserToggled = (toggled) => ({
  type: SET_USER_TOGGLED,
  payload: toggled,
});
