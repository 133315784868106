import React, { useState } from "react";
import { Form, Input, Button, Typography, message } from "antd";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../api/authApi";
import { useTranslation } from "react-i18next";

const ChangePassword = () => {
  const { t } = useTranslation("accounts");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      message.error(t("change-password.validation.passwords_not_match"));
      return;
    }

    try {
      await changePassword(oldPassword, newPassword);
      message.success(t("change-password.success"));
      navigate("/account/profile");
    } catch (error) {
      if (error.response && error.response.data) {
        const serverErrors = error.response.data;

        if (serverErrors.detail) {
          message.error(
            t("change-password.error.specific", { error: serverErrors.detail })
          );
        } else {
          const fieldsErrors = Object.keys(serverErrors).map((field) => ({
            name: field,
            errors: serverErrors[field],
          }));

          form.setFields(fieldsErrors);
        }
      } else {
        message.error(t("change-password.error.generic"));
      }
    }
  };

  return (
    <div className="change-password-container">
      <Typography.Title level={2}>
        {t("change-password.title")}
      </Typography.Title>
      <Form
        form={form}
        name="change_password"
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t("change-password.label.current_password")}
          name="current_password"
          rules={[
            {
              required: true,
              message: t(
                "change-password.validation.current_password_required"
              ),
            },
          ]}
        >
          <Input.Password
            name="current_password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label={t("change-password.label.new_password")}
          name="new_password"
          rules={[
            {
              required: true,
              message: t("change-password.validation.new_password_required"),
            },
          ]}
        >
          <Input.Password
            name="new_password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label={t("change-password.label.confirm_new_password")}
          name="confirm_new_password"
          dependencies={["new_password"]}
          rules={[
            {
              required: true,
              message: t(
                "change-password.validation.confirm_new_password_required"
              ),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t("change-password.validation.passwords_not_match"))
                );
              },
            }),
          ]}
        >
          <Input.Password
            name="confirm_new_password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {t("change-password.button")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangePassword;
