import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography, Spin, Result, Button, Form, Input, message } from "antd";
import { fetchInvitation, acceptInvitation } from "../api/teamsApi"; // Import the API functions

const AcceptInvitation = () => {
  const { t } = useTranslation("teams");
  const { guid, organizationId } = useParams(); // Extract the invitation key and organization ID from the URL
  const [loading, setLoading] = useState(true);
  const [invitationData, setInvitationData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (guid && organizationId) {
      fetchInvitationData(organizationId, guid);
    } else {
      setLoading(false);
    }
  }, [guid, organizationId]);

  const fetchInvitationData = async (organizationId, guid) => {
    try {
      const data = await fetchInvitation(organizationId, guid);
      setInvitationData(data.invitation);
      setUserData(data.invitee);
      setStatus("pending");
    } catch (error) {
      message.error(t("accept-invitation.error.fetch"));
      setStatus("error");
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptInvitation = async (values) => {
    try {
      await acceptInvitation(organizationId, guid, values); // Call the API to accept the invitation with the form values
      message.success(t("accept-invitation.success"));
      setStatus("success");
    } catch (error) {
      message.error(t("accept-invitation.error.accept"));
      setStatus("error");
    } finally {
      setLoading(false);
    }
  };

  const handleBackToLogin = () => {
    navigate("/account/login"); // Redirect to the teams list after acceptance
  };

  if (loading) {
    return (
      <div
        className="invitation-accept-container"
        style={{
          textAlign: "center",
        }}
      >
        <Spin size="large" />
        <Typography.Title level={3}>
          {t("accept-invitation.loading")}
        </Typography.Title>
      </div>
    );
  }

  return (
    <div className="invitation-accept-container">
      {status === "success" ? (
        <Result
          status="success"
          title={t("accept-invitation.result.success.title")}
          subTitle={t("accept-invitation.result.success.text")}
          extra={[
            <Button type="primary" key="teams" onClick={handleBackToLogin}>
              {t("accept-invitation.result.success.button")}
            </Button>,
          ]}
        />
      ) : status === "error" ? (
        <Result
          status="error"
          title={t("accept-invitation.result.error.title")}
          subTitle={t("accept-invitation.result.error.text")}
        />
      ) : status === "pending" ? (
        <div className="invitation-form-container">
          <Typography.Title level={2} style={{ textAlign: "center" }}>
            {t("accept-invitation.title")}
          </Typography.Title>
          <Typography.Paragraph style={{ textAlign: "center" }}>
            {userData?.email
              ? t("accept-invitation.description_email", {
                  email: userData.email,
                })
              : t("accept-invitation.description")}
          </Typography.Paragraph>
          <Form
            layout="vertical"
            onFinish={handleAcceptInvitation}
            initialValues={{
              first_name: userData?.first_name || "",
              last_name: userData?.last_name || "",
              email: userData?.email || "",
            }}
          >
            {!userData?.is_active && (
              <>
                <Form.Item
                  label={t("accept-invitation.label.first_name")}
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: t(
                        "accept-invitation.validation.first_name_required"
                      ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t("accept-invitation.label.last_name")}
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: t(
                        "accept-invitation.validation.last_name_required"
                      ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t("accept-invitation.label.password")}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t(
                        "accept-invitation.validation.password_required"
                      ),
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label={t("accept-invitation.label.confirm_password")}
                  name="confirm_password"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: t(
                        "accept-invitation.validation.confirm_password_required"
                      ),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            t(
                              "accept-invitation.validation.passwords_not_match"
                            )
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </>
            )}
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit" block>
                {t("accept-invitation.button")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <Result
          status="info"
          title={t("accept-invitation.result.info.title")}
          subTitle={t("accept-invitation.result.info.text")}
        />
      )}
    </div>
  );
};

export default AcceptInvitation;
