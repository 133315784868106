import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Typography, Form, Input, Button, message } from "antd";
import { loginUser } from "../actions/authActions";
import GoogleLoginButton from "./GoogleLogin";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation("accounts");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [form] = Form.useForm();

  // Map specific error messages from the API to translation keys
  const errorMapping = {
    "No active account found with the given credentials": "no_active_account",
  };

  const handleSubmit = async () => {
    try {
      const data = await dispatch(loginUser(credentials));
      if (data) {
        message.success(t("login.success"));
        navigate("/dashboard/");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const serverErrors = error.response.data;

        if (serverErrors.detail) {
          message.error(
            t("login.error.detail", {
              error: t(
                `login.error.specific.${errorMapping[serverErrors.detail]}`
              ),
            })
          );
        } else {
          const fieldsErrors = Object.keys(serverErrors).map((field) => ({
            name: field,
            errors: serverErrors[field],
          }));

          form.setFields(fieldsErrors);
        }
      } else {
        message.error(t("login.error.generic"));
      }
    }
  };

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="account-login-container">
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        {t("login.title")}
      </Typography.Title>
      <Form form={form} name="login" layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label={t("login.label.email")}
          name="email"
          rules={[
            { required: true, message: t("login.validation.email_required") },
          ]}
        >
          <Input
            type="text"
            name="email"
            value={credentials.email}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          label={t("login.label.password")}
          name="password"
          rules={[
            {
              required: true,
              message: t("login.validation.password_required"),
            },
          ]}
        >
          <Input.Password
            name="password"
            value={credentials.password}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {t("login.button")}
          </Button>
        </Form.Item>
      </Form>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <Link to="/account/password-reset">
          {t("login.link.forgot_password")}
        </Link>
        <Link to="/account/register">{t("login.link.register")}</Link>
      </div>

      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <GoogleLoginButton />
      </div>
    </div>
  );
};

export default Login;
