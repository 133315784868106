import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";

const Dashboard = () => {
  const { t } = useTranslation("dashboard");

  return (
    <div>
      <Typography.Title level={2}>{t("title")}</Typography.Title>
      <p>{t("text")}</p>
    </div>
  );
};

export default Dashboard;
