import { getApiClient } from "../../accounts/api/apiUtils"; // Import the API client

const apiClient = getApiClient();

// Fetch notifications for the authenticated user
export const fetchNotifications = async () => {
  const response = await apiClient.get("/notifications/");
  return response.data;
};

// Mark a notification as read
export const markNotificationAsRead = async (notificationId) => {
  const response = await apiClient.patch(`/notifications/${notificationId}/`, {
    is_read: true,
  });
  return response.data;
};

// Mark a notification as unread
export const markNotificationAsUnread = async (notificationId) => {
  const response = await apiClient.patch(`/notifications/${notificationId}/`, {
    is_read: false,
  });
  return response.data;
};

// Delete a notification
export const deleteNotification = async (notificationId) => {
  const response = await apiClient.delete(`/notifications/${notificationId}/`);
  return response.data;
};

// Mark all notifications as read
export const markAllNotificationsAsRead = async () => {
  const response = await apiClient.put("/notifications/mark-all-read/");
  return response.data;
};

// Mark all notifications as unread
export const markAllNotificationsAsUnread = async () => {
  const response = await apiClient.put("/notifications/mark-all-unread/");
  return response.data;
};

// Delete all notifications
export const deleteAllNotifications = async () => {
  const response = await apiClient.delete("/notifications/delete-all/");
  return response.data;
};
