import {
  getProfile,
  login,
  handleGoogleCallback,
  getLinkedSocialAccounts,
  deleteLinkedSocialAccount,
  createPassword,
} from "../api/authApi";

// Load User
export const loadUser = () => async (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  if (accessToken && refreshToken) {
    dispatch({ type: "AUTH_LOADING" });

    try {
      const data = await getProfile();

      dispatch({
        type: "USER_LOADED",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "LOGIN_FAILURE",
        error: error.message,
      });
    }
  } else {
    dispatch({ type: "LOGIN_FAILURE", error: "No token found" });
  }
};

// Login User
export const loginUser = (credentials) => async (dispatch) => {
  try {
    const data = await login(credentials);
    const { access, refresh } = data;

    // Store tokens in local storage
    localStorage.setItem("accessToken", access);
    localStorage.setItem("refreshToken", refresh);

    dispatch({
      type: "LOGIN_SUCCESS",
      payload: data,
    });

    // Immediately load the user profile after login
    const profileData = await getProfile();

    dispatch({
      type: "USER_LOADED",
      payload: profileData,
    });

    return data;
  } catch (error) {
    dispatch({
      type: "LOGIN_FAILURE",
      error: error.message,
    });
    throw error;
  }
};

// Set Password
export const setPassword = (newPassword) => async (dispatch) => {
  try {
    await createPassword(newPassword); // Call the API to set the password

    dispatch({
      type: "SET_PASSWORD_SUCCESS",
    });

    // Fetch the updated user profile after setting the password
    const updatedUser = await getProfile();

    dispatch({
      type: "USER_LOADED",
      payload: updatedUser, // Update user profile in Redux
    });

    return true;
  } catch (error) {
    dispatch({
      type: "SET_PASSWORD_FAILURE",
      error: error.message,
    });
    throw error;
  }
};

// Logout User
export const logoutUser = () => (dispatch) => {
  // Remove tokens from local storage
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");

  // Dispatch logout action
  dispatch({ type: "LOGOUT" });
};

// Handle Google OAuth Callback
export const googleOAuthCallback = (code, state) => async (dispatch) => {
  try {
    const response = await handleGoogleCallback(code, state);
    const { access, refresh } = response;

    // Store tokens in local storage
    localStorage.setItem("accessToken", access);
    localStorage.setItem("refreshToken", refresh);

    // Dispatch success action
    dispatch({
      type: "GOOGLE_LOGIN_SUCCESS",
      payload: { access, refresh },
    });

    // Load user information
    const user = await getProfile();
    dispatch({
      type: "USER_LOADED",
      payload: user,
    });

    return response; // Return the response so it can be awaited
  } catch (error) {
    dispatch({
      type: "LOGIN_FAILURE",
      error: error.message,
    });
    throw error; // Rethrow the error so the caller can handle it
  }
};

// Fetch linked social accounts
export const fetchLinkedSocialAccounts = (userId) => async (dispatch) => {
  try {
    const data = await getLinkedSocialAccounts(userId);

    dispatch({
      type: "FETCH_LINKED_SOCIALS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "FETCH_LINKED_SOCIALS_FAILURE",
      error: error.message,
    });
  }
};

// Delete a linked social account
export const removeLinkedSocialAccount =
  (userId, socialId) => async (dispatch) => {
    try {
      await deleteLinkedSocialAccount(userId, socialId);

      dispatch({
        type: "REMOVE_LINKED_SOCIAL_SUCCESS",
        payload: socialId,
      });
    } catch (error) {
      dispatch({
        type: "REMOVE_LINKED_SOCIAL_FAILURE",
        error: error.message,
      });
    }
  };
