import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { activateUser } from "../api/authApi";
import { Typography, Spin, Result, Button, message } from "antd";
import { useTranslation } from "react-i18next";

const Activate = () => {
  const { t } = useTranslation("accounts");
  const { uid, token } = useParams();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      activateAccount(uid, token);
    } else {
      setLoading(false);
    }
  }, [uid, token]);

  const activateAccount = async (uid, token) => {
    try {
      await activateUser(uid, token);
      message.success(t("activate.success"));
      setStatus("success");
    } catch (error) {
      message.error(t("activate.error"));
      setStatus("error");
    } finally {
      setLoading(false);
    }
  };

  const handleBackToLogin = () => {
    navigate("/account/login");
  };

  if (loading) {
    return (
      <div
        className="activate-account-container"
        style={{ textAlign: "center" }}
      >
        <Spin size="large" />
        <Typography.Title level={3}>{t("activate.verifying")}</Typography.Title>
      </div>
    );
  }

  return (
    <div className="activate-account-container">
      {status === "success" ? (
        <Result
          status="success"
          title={t("activate.success.title")}
          subTitle={t("activate.success.text")}
          extra={[
            <Button type="primary" key="login" onClick={handleBackToLogin}>
              {t("activate.success.button")}
            </Button>,
          ]}
        />
      ) : (
        <Result
          status="error"
          title={t("activate.error.title")}
          subTitle={t("activate.error.text")}
          extra={[
            <Button type="primary" key="login" onClick={handleBackToLogin}>
              {t("activate.error.button")}
            </Button>,
          ]}
        />
      )}
    </div>
  );
};

export default Activate;
