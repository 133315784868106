import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, Form, Input, Button } from "antd";
import { inviteUser } from "../actions/teamsActions";

const InviteUserModal = ({ isVisible, onClose, teamId }) => {
  const { t } = useTranslation("teams"); // Using the 'teams' namespace
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleInviteUser = (values) => {
    dispatch(inviteUser(teamId, values.email)).then(() => {
      onClose(); // Close the modal after inviting the user
      form.resetFields(); // Reset form fields
    });
  };

  return (
    <Modal
      title={t("invite-user.title")}
      visible={isVisible}
      onCancel={onClose}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleInviteUser}>
        <Form.Item
          label={t("invite-user.label.email")}
          name="email"
          rules={[
            {
              required: true,
              message: t("invite-user.validation.email_required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("invite-user.button")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InviteUserModal;
