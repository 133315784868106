import axios from "axios";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

let isRefreshing = false;
let refreshSubscribers = [];

const addSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

const onRefreshed = (newToken) => {
  refreshSubscribers.map((callback) => callback(newToken));
  refreshSubscribers = [];
};

export const getApiClient = () => {
  const apiClient = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
  });

  apiClient.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  apiClient.interceptors.response.use(
    (response) => {
      console.log("API RESPONSE: ", response);
      return response;
    },
    async (error) => {
      const { response } = error;
      const originalRequest = error.config;
      console.log("API RESPONSE: ", response);

      if (response.status === 401 && !originalRequest._retry) {
        if (!isRefreshing) {
          isRefreshing = true;
          originalRequest._retry = true;

          const refreshToken = localStorage.getItem("refreshToken");
          if (!refreshToken) {
            console.error("No refresh token found.");
            return Promise.reject(error);
          }

          try {
            console.log("Attempting to refresh token...");
            const { data } = await axios.post(
              `${API_BASE_URL}auth/jwt/refresh/`,
              {
                refresh: refreshToken,
              }
            );

            console.log("Token refreshed successfully:", data);

            localStorage.setItem("accessToken", data.access);
            localStorage.setItem("refreshToken", data.refresh);
            apiClient.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${data.access}`;

            onRefreshed(data.access);

            isRefreshing = false;

            originalRequest.headers["Authorization"] = `Bearer ${data.access}`;
            return apiClient(originalRequest);
          } catch (refreshError) {
            console.error(
              "Token refresh failed:",
              refreshError.response || refreshError
            );
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            isRefreshing = false;
            refreshSubscribers = [];
            window.location.href = "/account/login";
            return Promise.reject(refreshError);
          }
        }

        return new Promise((resolve) => {
          addSubscriber((newToken) => {
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            resolve(axios(originalRequest));
          });
        });
      }

      return Promise.reject(error);
    }
  );

  return apiClient;
};
