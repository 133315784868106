import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography, message } from "antd";
import { confirmPasswordReset } from "../api/authApi";
import { useTranslation } from "react-i18next";

const PasswordResetConfirm = () => {
  const { t } = useTranslation("accounts");
  const { uid, token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      message.error(t("password-reset-confirm.validation.passwords_not_match"));
      return;
    }

    try {
      await confirmPasswordReset(uid, token, newPassword);
      message.success(t("password-reset-confirm.success"));
      navigate("/account/login");
    } catch (error) {
      if (error.response && error.response.data) {
        const serverErrors = error.response.data;

        if (serverErrors.token || serverErrors.detail) {
          message.error(
            t("password-reset-confirm.error.specific.expired_link")
          );
        } else {
          form.setFields([
            { name: "new_password", errors: serverErrors.new_password || [] },
          ]);
        }
      } else {
        message.error(t("password-reset-confirm.error.generic"));
      }
    }
  };

  return (
    <div className="password-reset-confirm-container">
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        {t("password-reset-confirm.title")}
      </Typography.Title>
      <Form
        form={form}
        name="reset_confirm"
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t("password-reset-confirm.label.new_password")}
          name="new_password"
          rules={[
            {
              required: true,
              message: t(
                "password-reset-confirm.validation.new_password_required"
              ),
            },
          ]}
        >
          <Input.Password
            name="new_password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label={t("password-reset-confirm.label.re_password")}
          name="re_password"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: t(
                "password-reset-confirm.validation.re_password_required"
              ),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    t("password-reset-confirm.validation.passwords_not_match")
                  )
                );
              },
            }),
          ]}
        >
          <Input.Password
            name="re_password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {t("password-reset-confirm.button")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordResetConfirm;
