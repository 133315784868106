// components\PrivateLayout.js

import React, { useState, useEffect } from "react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../accounts/actions/authActions";
import { fetchTeams, setCurrentTeam } from "../../teams/actions/teamsActions"; // Updated import for setCurrentTeam
import { setThemeSettings } from "../actions/layoutActions";
import {
  Layout,
  Menu,
  Button,
  Avatar,
  Dropdown,
  theme,
  Select,
  Typography,
  Empty,
} from "antd";
import {
  DashboardOutlined,
  UserOutlined,
  TeamOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DownOutlined,
  LogoutOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useResponsive } from "../hooks/useResponsive";
import logoLight from "../../../assets/logo-light.png";
import logoDark from "../../../assets/logo-dark.png";
import iconLight from "../../../assets/icon-light.png";
import iconDark from "../../../assets/icon-dark.png";
import TeamAvatar from "../../teams/components/TeamAvatar";
import CreateTeamModal from "../../teams/components/CreateTeamModal";
import LanguageSelector from "./LanguageSelector";
import ThemeToggle from "./ThemeToggle";
import NotificationCenter from "./NotificationCenter";
import { useTranslation } from "react-i18next";

const SITE_NAME = process.env.REACT_APP_SITE_NAME;

const { Header, Content, Footer, Sider } = Layout;
const { Option } = Select;

const PrivateLayout = () => {
  const { t } = useTranslation("layout");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((state) => state.auth.user);
  const themeMode = useSelector((state) => state.layout.themeMode);
  const themeSettings = useSelector((state) => state.layout.themeSettings); // Get theme settings from Redux
  const teams = useSelector((state) => state.teams.teams);
  const currentTeam = useSelector((state) => state.teams.currentTeam);
  const [isCreateTeamModalVisible, setIsCreateTeamModalVisible] =
    useState(false);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { mdDown, mdUp } = useResponsive();

  const [collapsed, setCollapsed] = useState(false);
  const [collapsedWidth, setCollapsedWidth] = useState(80);
  const [selectedTeam, setSelectedTeam] = useState(null); // State for selected team in the dropdown

  useEffect(() => {
    // Fetch teams on mount
    dispatch(fetchTeams());
  }, [dispatch]);

  useEffect(() => {
    if (teams.length > 0 && !currentTeam) {
      const defaultTeam = teams[0]; // Auto-select the first team if none is selected
      setSelectedTeam(defaultTeam.id);
      dispatch(setCurrentTeam(defaultTeam));
    } else if (currentTeam) {
      setSelectedTeam(currentTeam.id); // Keep the currently selected team
    } else {
      setSelectedTeam(null);
    }
  }, [teams, currentTeam, dispatch]);

  useEffect(() => {
    // Update theme settings when the current team changes
    if (currentTeam) {
      dispatch(
        setThemeSettings({
          primaryColor: currentTeam.primary_color || "#0652DD",
          secondaryColor: currentTeam.secondary_color || "#ff4d4f",
        })
      );
    }
  }, [currentTeam, dispatch]);

  // Apply theme settings directly from Redux
  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--primary-color", themeSettings.primaryColor);
    root.style.setProperty("--secondary-color", themeSettings.secondaryColor);
  }, [themeSettings]);

  const handleTeamChange = (teamId) => {
    const selectedTeamData = teams.find((team) => team.id === teamId);
    if (selectedTeamData) {
      setSelectedTeam(teamId); // Update only the dropdown selected team
      dispatch(setCurrentTeam(selectedTeamData)); // Set the selected team as currentTeam in Redux
    }
  };

  const toggleMenu = () => {
    if (mdDown) {
      setCollapsedWidth(collapsedWidth === 0 ? 80 : 0);
    } else {
      setCollapsed(!collapsed);
    }
  };

  const menuIcon = mdDown ? (
    collapsedWidth === 0 ? (
      <MenuUnfoldOutlined />
    ) : (
      <MenuFoldOutlined />
    )
  ) : collapsed ? (
    <MenuUnfoldOutlined />
  ) : (
    <MenuFoldOutlined />
  );

  useEffect(() => {
    const updateLayout = () => {
      if (mdDown) {
        setCollapsed(true);
        setCollapsedWidth(0);
      } else {
        setCollapsedWidth(80);
      }
    };

    updateLayout();

    window.addEventListener("resize", updateLayout);

    return () => window.removeEventListener("resize", updateLayout);
  }, [mdDown]);

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/account/login");
  };

  const userMenuItems = [
    {
      key: "profile",
      icon: <UserOutlined />,
      label: t("private.link.profile"),
      onClick: () => navigate("/account/profile"),
    },
    {
      key: "logout",
      icon: <LogoutOutlined />,
      label: t("private.link.logout"),
      onClick: handleLogout,
    },
  ];

  const userMenu = <Menu items={userMenuItems} />;

  const avatarDropdown = (
    <Dropdown overlay={userMenu} trigger={["click"]}>
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <Avatar
          style={{ backgroundColor: "var(--primary-color)" }}
          size="large"
        >
          {user ? user.email.charAt(0).toUpperCase() : "U"}
        </Avatar>
        {mdUp && (
          <span style={{ marginLeft: 8 }}>
            {user ? user.email.trim() : "User"}
            <DownOutlined style={{ marginLeft: 8 }} />
          </span>
        )}
      </div>
    </Dropdown>
  );

  const menuItems = [
    {
      key: "/dashboard",
      icon: <DashboardOutlined />,
      label: <Link to="/dashboard">{t("private.link.dashboard")}</Link>,
    },
    {
      key: "/account/profile",
      icon: <UserOutlined />,
      label: <Link to="/account/profile">{t("private.link.profile")}</Link>,
    },
    {
      key: "/teams/",
      icon: <TeamOutlined />,
      label: <Link to="/teams/">{t("private.link.teams")}</Link>,
    },
  ];

  const logoSrc = collapsed
    ? themeMode === "dark"
      ? iconDark
      : iconLight
    : themeMode === "dark"
    ? logoDark
    : logoLight;

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          theme={themeMode}
          trigger={null}
          collapsible
          collapsed={collapsed}
          collapsedWidth={collapsedWidth}
        >
          <img
            className="logo"
            src={logoSrc}
            style={{
              maxHeight: "48px",
              maxWidth: "100%",
              margin: "8px auto",
              display: "block",
            }}
            onClick={() => navigate("/")}
            alt="Logo"
          />
          <div
            style={{
              padding: "5px",
              position: "relative",
            }}
          >
            {/* Team Selection Dropdown */}
            <Select
              style={{
                width: "100%",
                height: "48px",
                maxWidth: "100%",
                position: "relative",
                zIndex: 1000,
              }}
              value={selectedTeam}
              onChange={handleTeamChange}
              popupMatchSelectWidth={false}
              getPopupContainer={(trigger) => trigger.parentNode}
              optionLabelProp="label"
              labelInValue={false}
              placeholder="Select a team" // Placeholder when no option is selected
            >
              {teams.length === 0 ? (
                <Option disabled key="no-teams">
                  <Empty
                    description={
                      <span>
                        No teams available. <br />
                        Create a new team or wait for an invitation.
                      </span>
                    }
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  >
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => setIsCreateTeamModalVisible(true)} // Open the create team modal
                    >
                      Create Team
                    </Button>
                  </Empty>
                </Option>
              ) : (
                teams.map((team) => (
                  <Option
                    key={team.id}
                    value={team.id}
                    label={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          overflow: "hidden",
                        }}
                      >
                        <TeamAvatar
                          image={team.image}
                          name={team.name}
                          primaryColor={team.primary_color}
                          size={24}
                        />
                        {!collapsed && (
                          <Typography.Text strong style={{ marginLeft: "8px" }}>
                            {team.name}
                          </Typography.Text>
                        )}
                      </div>
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <TeamAvatar
                        image={team.image}
                        name={team.name}
                        primaryColor={team.primary_color}
                        size={32}
                      />
                      <Typography.Text style={{ marginLeft: "8px" }}>
                        {team.name}
                      </Typography.Text>
                    </div>
                  </Option>
                ))
              )}
            </Select>
          </div>
          <Menu
            theme={themeMode}
            mode="inline"
            selectedKeys={[location.pathname]}
            items={menuItems}
          />
        </Sider>
        <Layout>
          <Header
            style={{
              padding: "0 16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: colorBgContainer,
            }}
          >
            <Button
              type="text"
              icon={menuIcon}
              onClick={toggleMenu}
              style={{
                fontSize: "16px",
              }}
            />
            <span></span>
            <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
              {avatarDropdown}
              <NotificationCenter />
              <LanguageSelector />
              <ThemeToggle />
            </div>
          </Header>
          <Content
            style={{
              margin: "16px",
            }}
          >
            <div
              style={{
                padding: "24px",
                minHeight: "360px",
                backgroundColor: colorBgContainer,
              }}
            >
              <Outlet /> {/* Render the nested routes here */}
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>{SITE_NAME} ©2024</Footer>
        </Layout>
      </Layout>
      <CreateTeamModal
        isVisible={isCreateTeamModalVisible}
        onClose={() => setIsCreateTeamModalVisible(false)}
      />
    </>
  );
};

export default PrivateLayout;
