import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography, message } from "antd";
import { confirmUsernameReset } from "../api/authApi";
import { useTranslation } from "react-i18next";

const UsernameResetConfirm = () => {
  const { t } = useTranslation("accounts");
  const { uid, token } = useParams();
  const [newUsername, setNewUsername] = useState("");
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      await confirmUsernameReset(uid, token, newUsername);
      message.success(t("username-reset-confirm.success"));
      navigate("/account/login");
    } catch (error) {
      if (error.response && error.response.data) {
        const serverErrors = error.response.data;

        if (serverErrors.detail) {
          message.error(
            t("username-reset-confirm.error.specific.expired_link")
          );
        } else {
          form.setFields([
            { name: "new_username", errors: serverErrors.new_username || [] },
          ]);
        }
      } else {
        message.error(t("username-reset-confirm.error.generic"));
      }
    }
  };

  return (
    <div className="username-reset-confirm-container">
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        {t("username-reset-confirm.title")}
      </Typography.Title>
      <Form
        form={form}
        name="reset_username_confirm"
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t("username-reset-confirm.label.new_username")}
          name="new_username"
          rules={[
            {
              required: true,
              message: t(
                "username-reset-confirm.validation.new_username_required"
              ),
            },
          ]}
        >
          <Input
            name="new_username"
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {t("username-reset-confirm.button")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UsernameResetConfirm;
