import React, { useState } from "react";
import { Form, Input, Button, Typography, message } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPassword } from "../actions/authActions";
import { useTranslation } from "react-i18next";

const SetPassword = () => {
  const { t } = useTranslation("accounts");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      message.error(t("set-password.validation.passwords_not_match"));
      return;
    }

    try {
      await dispatch(setPassword(newPassword));
      message.success(t("set-password.success"));
      navigate("/account/profile");
    } catch (error) {
      if (error.response && error.response.data) {
        const serverErrors = error.response.data;

        if (serverErrors.detail) {
          message.error(
            t("set-password.error.specific", { error: serverErrors.detail })
          );
        } else {
          const fieldsErrors = Object.keys(serverErrors).map((field) => ({
            name: field,
            errors: serverErrors[field],
          }));

          form.setFields(fieldsErrors);
        }
      } else {
        message.error(t("set-password.error.generic"));
      }
    }
  };

  return (
    <div className="set-password-container">
      <Typography.Title level={2}>{t("set-password.title")}</Typography.Title>
      <Form
        form={form}
        name="set_password"
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t("set-password.label.new_password")}
          name="new_password"
          rules={[
            {
              required: true,
              message: t("set-password.validation.new_password_required"),
            },
          ]}
        >
          <Input.Password
            name="new_password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label={t("set-password.label.confirm_new_password")}
          name="confirm_new_password"
          dependencies={["new_password"]}
          rules={[
            {
              required: true,
              message: t(
                "set-password.validation.confirm_new_password_required"
              ),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t("set-password.validation.passwords_not_match"))
                );
              },
            }),
          ]}
        >
          <Input.Password
            name="confirm_new_password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {t("set-password.button")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SetPassword;
