import { useMediaQuery } from "react-responsive";

const breakpoints = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

export const useResponsive = () => {
  const xsUp = useMediaQuery({ minWidth: breakpoints.xs });
  const smUp = useMediaQuery({ minWidth: breakpoints.sm });
  const mdUp = useMediaQuery({ minWidth: breakpoints.md });
  const lgUp = useMediaQuery({ minWidth: breakpoints.lg });
  const xlUp = useMediaQuery({ minWidth: breakpoints.xl });
  const xxlUp = useMediaQuery({ minWidth: breakpoints.xxl });

  const smDown = useMediaQuery({ maxWidth: breakpoints.sm - 1 });
  const mdDown = useMediaQuery({ maxWidth: breakpoints.md - 1 });
  const lgDown = useMediaQuery({ maxWidth: breakpoints.lg - 1 });
  const xlDown = useMediaQuery({ maxWidth: breakpoints.xl - 1 });
  const xxlDown = useMediaQuery({ maxWidth: breakpoints.xxl - 1 });

  return {
    xsUp,
    smUp,
    mdUp,
    lgUp,
    xlUp,
    xxlUp,
    smDown,
    mdDown,
    lgDown,
    xlDown,
    xxlDown,
  };
};
