const initialState = {
  accessToken: localStorage.getItem("accessToken"), // Persist access token in local storage
  refreshToken: localStorage.getItem("refreshToken"), // Persist refresh token in local storage
  isAuthenticated: false,
  user: null,
  loading: true,
  error: null,
  linkedSocials: [],
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
    case "GOOGLE_LOGIN_SUCCESS": // Handle Google login success - changed
      const { access, refresh } = action.payload;
      console.log("ACCESS TOKEN: ", access, "REFRESH TOKEN: ", refresh);
      return {
        ...state,
        accessToken: access,
        refreshToken: refresh,
        isAuthenticated: true,
        loading: false,
      };

    case "USER_LOADED":
      console.log("USER LOADED");
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        loading: false,
      };

    case "LOGIN_FAILURE":
    case "LOGOUT":
      return {
        ...state,
        accessToken: null,
        refreshToken: null,
        isAuthenticated: false,
        user: null,
        loading: false,
        error: action.error,
      };

    case "AUTH_LOADING":
      return {
        ...state,
        loading: true,
      };

    case "SET_PASSWORD_SUCCESS":
      return {
        ...state,
        user: { ...state.user, has_password: true },
      };

    case "FETCH_LINKED_SOCIALS_SUCCESS":
      return {
        ...state,
        linkedSocials: action.payload,
      };

    case "FETCH_LINKED_SOCIALS_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    case "REMOVE_LINKED_SOCIAL_LOADING":
      return {
        ...state,
      };

    case "REMOVE_LINKED_SOCIAL_SUCCESS":
      return {
        ...state,
        linkedSocials: state.linkedSocials.filter(
          (social) => social.id !== action.payload
        ),
      };

    case "REMOVE_LINKED_SOCIAL_FAILURE":
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}
