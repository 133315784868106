import React from "react";
import { Card, Row, Col, Space, Table, Typography } from "antd";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const TeamOverviewTab = ({ team }) => {
  const { t } = useTranslation("teams"); // Using the 'teams' namespace

  const userColumns = [
    {
      title: t("team-overview.users.columns.name"),
      dataIndex: ["user", "first_name"],
      key: "first_name",
      render: (_, record) =>
        `${record.user.first_name} ${record.user.last_name}`,
    },
    {
      title: t("team-overview.users.columns.email"),
      dataIndex: ["user", "email"],
      key: "email",
    },
    {
      title: t("team-overview.users.columns.role"),
      dataIndex: "is_admin",
      key: "is_admin",
      render: (is_admin) =>
        is_admin
          ? t("team-overview.users.role.admin")
          : t("team-overview.users.role.member"),
    },
  ];

  return (
    <Space direction="vertical" size="middle" style={{ display: "flex" }}>
      <Card title={t("team-overview.organization.title")}>
        <Row>
          <Col span={12}>
            <Typography.Text strong>
              {t("team-overview.organization.label.identifier")}:
            </Typography.Text>
          </Col>
          <Col span={12}>{team.id}</Col>
        </Row>
        <Row>
          <Col span={12}>
            <Typography.Text strong>
              {t("team-overview.organization.label.name")}:
            </Typography.Text>
          </Col>
          <Col span={12}>{team.name}</Col>
        </Row>
        <Row>
          <Col span={12}>
            <Typography.Text strong>
              {t("team-overview.organization.label.owner")}:
            </Typography.Text>
          </Col>
          <Col span={12}>
            {`${team.owner.user.first_name} ${team.owner.user.last_name}`}
          </Col>
        </Row>
      </Card>
      <Card title={t("team-overview.subscription.title")}></Card>
      <Card title={t("team-overview.billing.title")}></Card>
      <Card title={t("team-overview.users.title")}>
        <Table
          columns={userColumns}
          dataSource={team.users}
          pagination={false}
          scroll={{ x: "max-content" }}
        />
      </Card>
    </Space>
  );
};

export default TeamOverviewTab;
