import {
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAM_SUCCESS,
  CREATE_TEAM_SUCCESS,
  UPDATE_TEAM_SUCCESS,
  DELETE_TEAM_SUCCESS,
  INVITE_USER_SUCCESS,
  UPDATE_USER_ROLE_SUCCESS,
  DELETE_USER_SUCCESS,
  FETCH_PENDING_INVITATIONS_SUCCESS,
  DELETE_INVITATION_SUCCESS,
  SET_CURRENT_TEAM, // New action type
} from "../actions/teamsActions";

const initialState = {
  teams: [],
  currentTeam: null,
  pendingInvitations: [],
};

const teamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        teams: action.payload,
      };

    case FETCH_TEAM_SUCCESS: {
      const updatedTeams = state.teams.some(
        (team) => team.id === action.payload.id
      )
        ? state.teams.map((team) =>
            team.id === action.payload.id ? action.payload : team
          )
        : [...state.teams, action.payload];

      // Also update the current team if it matches the fetched team
      const isCurrentTeam =
        state.currentTeam && state.currentTeam.id === action.payload.id;

      return {
        ...state,
        teams: updatedTeams,
        currentTeam: isCurrentTeam ? action.payload : state.currentTeam,
      };
    }

    case CREATE_TEAM_SUCCESS:
      return {
        ...state,
        teams: [...state.teams, action.payload],
      };

    case UPDATE_TEAM_SUCCESS: {
      const updatedTeam = action.payload;
      return {
        ...state,
        teams: state.teams.map((team) =>
          team.id === updatedTeam.id ? { ...team, ...updatedTeam } : team
        ),
        currentTeam:
          state.currentTeam?.id === updatedTeam.id
            ? updatedTeam
            : state.currentTeam,
      };
    }

    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        teams: state.teams.filter((team) => team.id !== action.payload),
        currentTeam:
          state.currentTeam && state.currentTeam.id === action.payload
            ? null
            : state.currentTeam,
      };

    case INVITE_USER_SUCCESS: {
      const { teamId, email } = action.payload;
      return {
        ...state,
        teams: state.teams.map((team) =>
          team.id === teamId
            ? {
                ...team,
                pendingInvitations: [
                  ...(team.pendingInvitations || []),
                  { email },
                ],
              }
            : team
        ),
        currentTeam:
          state.currentTeam && state.currentTeam.id === teamId
            ? {
                ...state.currentTeam,
                pendingInvitations: [
                  ...(state.currentTeam.pendingInvitations || []),
                  { email },
                ],
              }
            : state.currentTeam,
      };
    }

    case UPDATE_USER_ROLE_SUCCESS: {
      const { teamId, user } = action.payload; // Destructure teamId and user from payload
      return {
        ...state,
        teams: state.teams.map((team) =>
          team.id === teamId
            ? {
                ...team,
                users: team.users.map((u) => (u.id === user.id ? user : u)),
              }
            : team
        ),
        currentTeam:
          state.currentTeam && state.currentTeam.id === teamId
            ? {
                ...state.currentTeam,
                users: state.currentTeam.users.map((u) =>
                  u.id === user.id ? user : u
                ),
              }
            : state.currentTeam,
      };
    }

    case DELETE_USER_SUCCESS: {
      const { teamId, userId } = action.payload;
      return {
        ...state,
        teams: state.teams.map((team) =>
          team.id === teamId
            ? {
                ...team,
                users: team.users.filter((user) => user.id !== userId),
              }
            : team
        ),
        currentTeam:
          state.currentTeam && state.currentTeam.id === teamId
            ? {
                ...state.currentTeam,
                users: state.currentTeam.users.filter(
                  (user) => user.id !== userId
                ),
              }
            : state.currentTeam,
      };
    }

    case FETCH_PENDING_INVITATIONS_SUCCESS:
      return {
        ...state,
        pendingInvitations: action.payload.invitations,
      };

    case DELETE_INVITATION_SUCCESS:
      return {
        ...state,
        pendingInvitations: state.pendingInvitations.filter(
          (invitation) => invitation.id !== action.payload
        ),
      };

    case SET_CURRENT_TEAM: // New action case
      return {
        ...state,
        currentTeam: action.payload,
      };

    default:
      return state;
  }
};

export default teamsReducer;
