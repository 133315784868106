import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Typography } from "antd";
import { getProfile, updateProfile } from "../api/authApi";
import { useTranslation } from "react-i18next";

const EditProfile = () => {
  const { t } = useTranslation("accounts");
  const [profile, setProfile] = useState({
    email: "",
    first_name: "",
    last_name: "",
  });
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getProfile();
        setProfile(data);
        form.setFieldsValue(data);
        setLoading(false);
      } catch (error) {
        message.error(t("edit-profile.error"));
        setLoading(false);
      }
    };

    fetchProfile();
  }, [form, t]);

  const handleProfileUpdate = async (values) => {
    try {
      await updateProfile(values);
      message.success(t("edit-profile.success"));
    } catch (error) {
      message.error(t("edit-profile.error"));
    }
  };

  return (
    <div className="edit-profile-container">
      <Typography.Title level={2}>{t("edit-profile.title")}</Typography.Title>
      <Form
        form={form}
        name="edit_profile"
        layout="vertical"
        initialValues={profile}
        onFinish={handleProfileUpdate}
        disabled={loading}
      >
        <Form.Item
          label={t("edit-profile.label.email")}
          name="email"
          rules={[
            {
              required: true,
              message: t("edit-profile.validation.email_required"),
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label={t("edit-profile.label.first_name")}
          name="first_name"
          rules={[
            {
              required: true,
              message: t("edit-profile.validation.first_name_required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("edit-profile.label.last_name")}
          name="last_name"
          rules={[
            {
              required: true,
              message: t("edit-profile.validation.last_name_required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            {t("edit-profile.button")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditProfile;
