import React from "react";
import { Outlet } from "react-router-dom";
import { Layout, Row, Col, Typography } from "antd";
import { useResponsive } from "../hooks/useResponsive";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import ThemeToggle from "./ThemeToggle";

const SITE_NAME = process.env.REACT_APP_SITE_NAME;

const { Header, Content, Footer } = Layout;

const PublicLayout = () => {
  const { t } = useTranslation("layout");
  const { mdUp, mdDown } = useResponsive();

  return (
    <Layout
      style={{
        minHeight: "100vh",
        position: "relative",
      }}
    >
      {/* Language Selector and Theme Toggle */}
      <div
        style={{
          position: "absolute",
          top: 20,
          right: 20,
          zIndex: 1000,
          display: "flex",
          alignItems: "center",
          gap: "15px",
        }}
      >
        {/* Language Selector */}
        <LanguageSelector />

        {/* Theme Toggle */}
        <ThemeToggle />
      </div>

      <Row style={{ height: "100%", flexDirection: mdDown ? "column" : "row" }}>
        <Col
          span={mdUp ? 12 : 24}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center", // Centers content vertically and horizontally
            minHeight: "100vh",
          }}
        >
          {mdDown && (
            <Header
              style={{
                backgroundColor: "transparent",
                textAlign: "center",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <Typography.Text strong style={{ fontSize: "1.5em" }}>
                {SITE_NAME}
              </Typography.Text>
            </Header>
          )}
          <Content
            style={{
              padding: "24px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Outlet />
          </Content>
          {mdDown && (
            <Footer
              style={{
                backgroundColor: "transparent",
                textAlign: "center",
                width: "100%",
              }}
            >
              {SITE_NAME} ©2024
            </Footer>
          )}
        </Col>

        {mdUp && (
          <>
            <Col
              span={12}
              style={{
                backgroundColor: "var(--primary-color)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#fff",
                minHeight: "100vh",
              }}
            >
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Typography.Title level={1} style={{ color: "#fff" }}>
                  {t("public.welcome", {
                    site_name: SITE_NAME,
                  })}
                </Typography.Title>
              </div>

              <Footer
                style={{
                  backgroundColor: "transparent",
                  textAlign: "center",
                  color: "#fff",
                  width: "100%",
                }}
              >
                {SITE_NAME} ©2024
              </Footer>
            </Col>
          </>
        )}
      </Row>
    </Layout>
  );
};

export default PublicLayout;
