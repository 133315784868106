import { combineReducers } from "redux";
import authReducer from "./accounts/reducers/authReducer";
import layoutReducer from "./layout/reducers/layoutReducer";
import teamsReducer from "./teams/reducers/teamsReducer";
import notificationsReducer from "./notifications/reducers/notificationsReducer";

// Combine all your reducers into one root reducer
const rootReducer = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  teams: teamsReducer,
  notifications: notificationsReducer,
  // Add other reducers here
});

export default rootReducer;
