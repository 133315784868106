import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, Form, Input, Button } from "antd";
import { addTeam } from "../actions/teamsActions";

const CreateTeamModal = ({ isVisible, onClose }) => {
  const { t } = useTranslation("teams");
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleCreateTeam = (values) => {
    dispatch(addTeam(values)).then(() => {
      onClose(); // Close the modal after creating the team
      form.resetFields(); // Reset form fields
    });
  };

  return (
    <Modal
      title={t("create-team.title")}
      visible={isVisible}
      onCancel={onClose}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleCreateTeam}>
        <Form.Item
          label={t("create-team.label.name")}
          name="name"
          rules={[
            {
              required: true,
              message: t("create-team.validation.name_required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("create-team.button")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateTeamModal;
