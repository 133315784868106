import React from "react";
import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "../../accounts/routers/ProtectedRoute";
import PublicLayout from "../../layout/components/PublicLayout";
import PrivateLayout from "../../layout/components/PrivateLayout";

import TeamsList from "../components/TeamsList";
import TeamDetail from "../components/TeamDetail";
import AcceptInvitation from "../components/AcceptInvitation";

const TeamsRouter = () => {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route
          path="/invitations/accept/:organizationId/:guid"
          element={<AcceptInvitation />}
        />
      </Route>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <PrivateLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/" element={<TeamsList />} />
        <Route path="/:id" element={<TeamDetail />} />
      </Route>
    </Routes>
  );
};

export default TeamsRouter;
