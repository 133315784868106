import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Card,
  Typography,
  Row,
  Col,
  Progress,
  Button,
  Divider,
  Space,
} from "antd";
import { QuestionOutlined } from "@ant-design/icons";

const SubscriptionDetails = () => {
  const { t } = useTranslation("teams");

  return (
    <div className="team-subscription-tab">
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        {/* Subscription Plan Card */}
        <Card>
          <Row justify="space-between" align="middle">
            <Col>
              <Typography.Text type="secondary">Plan</Typography.Text>
              <Typography.Title level={4}>Growing Business</Typography.Title>
            </Col>
            <Col>
              <Typography.Text type="secondary">Payment</Typography.Text>
              <Typography.Title level={4}>$29 per month</Typography.Title>
            </Col>
            <Col>
              <Button type="link">Cancel subscription</Button>
              <Button type="primary">Upgrade</Button>
            </Col>
          </Row>

          <Divider />

          {/* Usage Stats */}
          <Row gutter={16}>
            <Col span={8}>
              <Typography.Text strong>Subscribers used</Typography.Text>
              <Progress percent={47} showInfo={false} style={{ flex: 1 }} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Typography.Text style={{ marginRight: "10px" }}>
                  1,183 (47%)
                </Typography.Text>
                <Typography.Text style={{ marginLeft: "10px" }}>
                  2,500
                </Typography.Text>
              </div>
            </Col>
            <Col span={8}>
              <Typography.Text strong>Monthly emails</Typography.Text>
              <Progress percent={12} showInfo={false} style={{ flex: 1 }} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Typography.Text style={{ marginRight: "10px" }}>
                  412
                </Typography.Text>
                <Typography.Text style={{ marginLeft: "10px" }}>
                  4000
                </Typography.Text>
              </div>
            </Col>
            <Col span={8}>
              <Typography.Text strong>Workflow used</Typography.Text>
              <Progress percent={0} showInfo={false} style={{ flex: 1 }} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Typography.Text style={{ marginRight: "10px" }}>
                  0
                </Typography.Text>
                <Typography.Text style={{ marginRight: "10px" }}>
                  10
                </Typography.Text>
              </div>
            </Col>
          </Row>
        </Card>

        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Card className="team-subscription-card" style={{ height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div style={{ flexGrow: 1, marginBottom: 16 }}>
                  <Typography.Text type="secondary">
                    Knowlegde Base
                  </Typography.Text>
                  <Row>
                    <Col
                      xs={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        minHeight: "100%",
                      }}
                    >
                      <QuestionOutlined
                        style={{
                          fontSize: "4em",
                          color: "var(--primary-color)",
                        }}
                      />
                    </Col>
                    <Col xs={18}>
                      <Typography.Title level={4}>Questions?</Typography.Title>
                      <Typography.Text>
                        Visit our{" "}
                        <Link to="/knowledge-base">knowledge base</Link> for
                        more information on subscriptions and billing.
                      </Typography.Text>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </Col>
          <Col className="team-subscription-card" xs={24} md={12}>
            <Card style={{ height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div style={{ flexGrow: 1, marginBottom: 16 }}>
                  <Typography.Text type="secondary">Support</Typography.Text>
                  <Typography.Title level={4}>Get in Touch</Typography.Title>
                  <Typography.Text>
                    Our support team is ready to help you with all your
                    questions!
                  </Typography.Text>
                </div>

                <div style={{ marginTop: "auto" }}>
                  <Button type="default">Contact Support</Button>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Space>
    </div>
  );
};

export default SubscriptionDetails;
