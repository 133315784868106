import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

// Suppress the ResizeObserver loop error
const suppressResizeObserverError = () => {
  const originalError = console.error;
  console.error = (...args) => {
    if (
      args[0] &&
      typeof args[0] === "string" &&
      (args[0].includes("ResizeObserver loop limit exceeded") ||
        args[0].includes(
          "ResizeObserver loop completed with undelivered notifications."
        ))
    ) {
      return;
    }
    originalError.apply(console, args);
  };
};

suppressResizeObserverError();

window.addEventListener(
  "error",
  (event) => {
    if (
      event.message === "ResizeObserver loop limit exceeded" ||
      event.message ===
        "ResizeObserver loop completed with undelivered notifications."
    ) {
      event.stopImmediatePropagation();
    }
  },
  true
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
