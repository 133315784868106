import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, Form, Input, Button, Upload, Image, message } from "antd";
import { editTeam } from "../actions/teamsActions";
import {
  UploadOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const EditTeamModal = ({ isVisible, onClose, team }) => {
  const { t } = useTranslation("teams");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [currentImage, setCurrentImage] = useState(team.image || null);

  useEffect(() => {
    setFileList([]);
    setCurrentImage(team.image || null);
    form.resetFields();
  }, [team, isVisible, form]);

  const handleUploadChange = ({ file, fileList }) => {
    setUploading(true); // Start loading indicator when file selection starts
    setCurrentImage(null);

    // Simulate file processing delay
    setTimeout(() => {
      setFileList(fileList);
      setUploading(false); // Stop loading indicator once file is ready
    }, 1000); // Adjust timeout as necessary for your use case
  };

  const handleRemoveImage = () => {
    setCurrentImage(null); // Remove the current image
    form.setFieldsValue({ image: null }); // Set image field to null
    setFileList([]); // Clear the upload file list
  };

  const handleEditTeam = async (values) => {
    try {
      const formData = new FormData();
      formData.append("name", values.name);

      if (fileList.length > 0) {
        formData.append("image", fileList[0].originFileObj);
      } else if (currentImage === null) {
        formData.append("image", ""); // Send empty value to remove image
      }

      await dispatch(editTeam(team.id, formData));
      message.success(t("edit-team.success"));
      onClose();
    } catch (error) {
      message.error(
        t("edit-team.error", { error: error.response.data.detail })
      );
    } finally {
      form.resetFields();
    }
  };

  return (
    <Modal
      title={t("edit-team.title")}
      visible={isVisible}
      onCancel={onClose}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleEditTeam}
        initialValues={{ name: team.name }}
      >
        <Form.Item
          label={t("edit-team.label.name")}
          name="name"
          rules={[
            {
              required: true,
              message: t("edit-team.validation.name_required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t("edit-team.label.image")}>
          {currentImage && (
            <div style={{ marginBottom: "10px" }}>
              <Image width={200} src={currentImage} />
              <Button
                icon={<DeleteOutlined />}
                type="danger"
                onClick={handleRemoveImage}
                style={{ marginTop: "10px" }}
              >
                {t("edit-team.button.remove_image")}
              </Button>
            </div>
          )}
          <Upload
            listType="picture"
            fileList={fileList}
            onChange={handleUploadChange}
            beforeUpload={() => false} // Prevent automatic upload
            showUploadList={{
              showRemoveIcon: !uploading,
            }} // Hide remove icon while uploading
            maxCount={1}
          >
            <Button icon={uploading ? <LoadingOutlined /> : <UploadOutlined />}>
              {uploading
                ? t("edit-team.button.loading")
                : t("edit-team.button.select_image")}
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={uploading}>
            {t("edit-team.button.save")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditTeamModal;
