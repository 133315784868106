import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Typography, message, Result } from "antd";
import { requestPasswordReset } from "../api/authApi";
import { useTranslation } from "react-i18next";

const PasswordResetRequest = () => {
  const { t } = useTranslation("accounts");
  const [email, setEmail] = useState("");
  const [form] = Form.useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async () => {
    try {
      await requestPasswordReset(email);
      message.error(t("password-reset-request.success"));
      setIsSubmitted(true);
      form.resetFields();
    } catch (error) {
      message.error(t("password-reset-request.error"));
    }
  };

  return (
    <div className="password-reset-request-container">
      {isSubmitted ? (
        <Result
          status="success"
          title={t("password-reset-request.result.title")}
          subTitle={t("password-reset-request.result.text")}
        />
      ) : (
        <>
          <Typography.Title level={2} style={{ textAlign: "center" }}>
            {t("password-reset-request.title")}
          </Typography.Title>
          <Form
            form={form}
            name="reset_request"
            layout="vertical"
            onFinish={handleSubmit}
          >
            <Form.Item
              label={t("password-reset-request.label.email")}
              name="email"
              rules={[
                {
                  required: true,
                  message: t(
                    "password-reset-request.validation.email_required"
                  ),
                },
              ]}
            >
              <Input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {t("password-reset-request.button")}
              </Button>
            </Form.Item>
          </Form>
        </>
      )}

      <div style={{ marginTop: "10px", textAlign: "center" }}>
        <Link to="/account/login">
          {t("password-reset-request.link.login")}
        </Link>
      </div>
    </div>
  );
};

export default PasswordResetRequest;
