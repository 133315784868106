import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "../../accounts/routers/ProtectedRoute";
import PrivateLayout from "../components/PrivateLayout";

const LayoutRouter = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <PrivateLayout />
          </ProtectedRoute>
        }
      ></Route>
    </Routes>
  );
};

export default LayoutRouter;
