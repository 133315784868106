import {
  getTeams,
  getTeamById,
  createTeam,
  updateTeam,
  deleteTeam,
  inviteUserToTeam,
  updateUserRoleInTeam,
  deleteUserFromTeam,
  getPendingInvitations,
  deleteInvitation,
} from "../api/teamsApi";

// Action types
export const FETCH_TEAMS_SUCCESS = "FETCH_TEAMS_SUCCESS";
export const FETCH_TEAM_SUCCESS = "FETCH_TEAM_SUCCESS";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const SET_CURRENT_TEAM = "SET_CURRENT_TEAM";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const FETCH_PENDING_INVITATIONS_SUCCESS =
  "FETCH_PENDING_INVITATIONS_SUCCESS";
export const DELETE_INVITATION_SUCCESS = "DELETE_INVITATION_SUCCESS";

// Fetch all teams
export const fetchTeams = () => async (dispatch) => {
  try {
    const teams = await getTeams();
    dispatch({
      type: FETCH_TEAMS_SUCCESS,
      payload: teams,
    });
  } catch (error) {
    console.error("Error fetching teams:", error);
    throw error;
  }
};

// Fetch a single team
export const fetchTeam = (id) => async (dispatch) => {
  try {
    const team = await getTeamById(id);
    dispatch({
      type: FETCH_TEAM_SUCCESS,
      payload: team,
    });
  } catch (error) {
    console.error("Error fetching team:", error);
    throw error;
  }
};

// Create a new team
export const addTeam = (teamData) => async (dispatch) => {
  try {
    const team = await createTeam(teamData);
    dispatch({
      type: CREATE_TEAM_SUCCESS,
      payload: team,
    });
  } catch (error) {
    console.error("Error creating team:", error);
    throw error;
  }
};

// Update a team
export const editTeam = (teamId, teamData) => async (dispatch) => {
  try {
    let formData;
    if (teamData.image) {
      formData = new FormData();
      Object.keys(teamData).forEach((key) => {
        formData.append(key, teamData[key]);
      });
    } else {
      formData = teamData;
    }

    const updatedTeam = await updateTeam(teamId, formData);
    dispatch({
      type: UPDATE_TEAM_SUCCESS,
      payload: updatedTeam,
    });
  } catch (error) {
    console.error("Error updating team:", error);
    throw error;
  }
};

// Delete a team
export const removeTeam = (teamId) => async (dispatch, getState) => {
  try {
    await deleteTeam(teamId);
    dispatch({
      type: DELETE_TEAM_SUCCESS,
      payload: teamId,
    });

    const currentTeam = getState().teams.currentTeam;

    if (currentTeam && currentTeam.id === teamId) {
      dispatch(setCurrentTeam(null));
    }
  } catch (error) {
    console.error("Error deleting team:", error);
    throw error;
  }
};

export const setCurrentTeam = (team) => ({
  type: SET_CURRENT_TEAM,
  payload: team,
});

// Invite user to a team
export const inviteUser = (teamId, email) => async (dispatch) => {
  try {
    await inviteUserToTeam(teamId, email);
    dispatch({
      type: INVITE_USER_SUCCESS,
      payload: { teamId, email },
    });

    dispatch(fetchPendingInvitations(teamId));
  } catch (error) {
    console.error("Error inviting user:", error);
    throw error;
  }
};

export const updateUserRole =
  (teamId, userId, roleData) => async (dispatch) => {
    try {
      const updatedUser = await updateUserRoleInTeam(teamId, userId, roleData);
      dispatch({
        type: UPDATE_USER_ROLE_SUCCESS,
        payload: { teamId, user: updatedUser.user },
      });
    } catch (error) {
      console.error("Error updating user role:", error);
      throw error;
    }
  };

export const removeUser = (teamId, userId) => async (dispatch) => {
  try {
    await deleteUserFromTeam(teamId, userId);
    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: userId,
    });
  } catch (error) {
    console.error("Error deleting user from team:", error);
    throw error;
  }
};

export const fetchPendingInvitations = (teamId) => async (dispatch) => {
  try {
    const invitations = await getPendingInvitations(teamId);
    dispatch({
      type: FETCH_PENDING_INVITATIONS_SUCCESS,
      payload: { teamId, invitations },
    });
  } catch (error) {
    console.error("Error fetching pending invitations:", error);
    throw error;
  }
};

// Delete a team
export const removeInvitation = (teamId, invitationId) => async (dispatch) => {
  try {
    await deleteInvitation(teamId, invitationId);
    dispatch({
      type: DELETE_INVITATION_SUCCESS,
      payload: invitationId,
    });
  } catch (error) {
    console.error("Error deleting invitation:", error);
    throw error;
  }
};
