import React from "react";
import { Result } from "antd";
import { useTranslation } from "react-i18next";

const VerifyEmail = () => {
  const { t } = useTranslation("accounts");

  return (
    <div className="verify-email-container">
      <Result
        status="info"
        title={t("verify-email.title")}
        subTitle={t("verify-email.text")}
      />
    </div>
  );
};

export default VerifyEmail;
