import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Typography, Form, Input, Button, message } from "antd";
import { register } from "../api/authApi";
import { useTranslation } from "react-i18next";

const Register = () => {
  const { t } = useTranslation("accounts");
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    email: "",
    password: "",
    re_password: "",
    first_name: "",
    last_name: "",
  });
  const [form] = Form.useForm();

  // Map specific error messages from the API to translation keys
  const errorMapping = {};

  const handleSubmit = async () => {
    try {
      await register(userData);
      message.success(t("register.success"));
      form.resetFields();
      navigate("/account/verify-email");
    } catch (error) {
      if (error.response && error.response.data) {
        const serverErrors = error.response.data;

        if (serverErrors.detail) {
          t("login.error.detail", {
            error: t(
              `register.error.specific.${errorMapping[serverErrors.detail]}`
            ),
          });
        } else {
          const fieldsErrors = Object.keys(serverErrors).map((field) => ({
            name: field,
            errors: serverErrors[field],
          }));

          form.setFields(fieldsErrors);
        }
      } else {
        message.error(t("register.error.generic"));
      }
    }
  };

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="account-register-container">
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        {t("register.title")}
      </Typography.Title>
      <Form
        form={form}
        name="register"
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t("register.label.email")}
          name="email"
          rules={[
            {
              required: true,
              message: t("login.validation.email_required"),
            },
          ]}
        >
          <Input
            type="email"
            name="email"
            value={userData.email}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          label={t("register.label.password")}
          name="password"
          rules={[
            {
              required: true,
              message: t("login.validation.password_required"),
            },
          ]}
        >
          <Input.Password
            name="password"
            value={userData.password}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          label={t("register.label.re_password")}
          name="re_password"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: t("login.validation.re_password_required"),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t("login.validation.passwords_not_match"))
                );
              },
            }),
          ]}
        >
          <Input.Password
            name="re_password"
            value={userData.re_password}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          label={t("register.label.first_name")}
          name="first_name"
          rules={[
            {
              required: true,
              message: t("login.validation.first_name_required"),
            },
          ]}
        >
          <Input
            type="text"
            name="first_name"
            value={userData.first_name}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          label={t("register.label.last_name")}
          name="last_name"
          rules={[
            {
              required: true,
              message: t("login.validation.last_name_required"),
            },
          ]}
        >
          <Input
            type="text"
            name="last_name"
            value={userData.last_name}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {t("register.button")}
          </Button>
        </Form.Item>
      </Form>

      <div style={{ marginTop: "10px", textAlign: "center" }}>
        <Link to="/account/login">{t("register.link.login")}</Link>
      </div>
    </div>
  );
};

export default Register;
