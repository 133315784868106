// context\LayoutContext.js

import React, { createContext, useEffect, useContext } from "react";
import { ConfigProvider, theme } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { toggleTheme } from "../actions/layoutActions"; // Import toggleTheme action

// Create the context
const LayoutContext = createContext();

// Custom hook to use the LayoutContext
export const useLayout = () => useContext(LayoutContext);

// Create a provider component
export const LayoutContextProvider = ({ children }) => {
  const dispatch = useDispatch(); // Get the Redux dispatch function
  const themeMode = useSelector((state) => state.layout.themeMode); // Get themeMode from Redux
  const themeSettings = useSelector((state) => state.layout.themeSettings); // Get themeSettings from Redux
  const userToggled = useSelector((state) => state.layout.userToggled); // Get userToggled from Redux

  const getAntdTheme = () => {
    return themeMode === "light"
      ? {
          algorithm: theme.defaultAlgorithm,
          token: {
            colorPrimary: themeSettings.primaryColor,
            colorSecondary: themeSettings.secondaryColor,
            colorBgBase: themeSettings.backgroundColor,
          },
        }
      : {
          algorithm: theme.darkAlgorithm,
          token: {
            colorPrimary: themeSettings.primaryColor,
            colorSecondary: themeSettings.secondaryColor,
            colorBgBase: "#141414",
          },
        };
  };

  useEffect(() => {
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");

    const updateTheme = (e) => {
      if (!userToggled) {
        if (e.matches && themeMode !== "dark") {
          dispatch(toggleTheme());
        } else if (!e.matches && themeMode !== "light") {
          dispatch(toggleTheme());
        }
      }
    };

    if (!userToggled) {
      if (prefersDarkScheme.matches && themeMode !== "dark") {
        dispatch(toggleTheme());
      } else if (!prefersDarkScheme.matches && themeMode !== "light") {
        dispatch(toggleTheme());
      }
    }

    prefersDarkScheme.addEventListener("change", updateTheme);

    return () => {
      prefersDarkScheme.removeEventListener("change", updateTheme);
    };
  }, [dispatch, themeMode, userToggled]);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--primary-color", themeSettings.primaryColor);
    root.style.setProperty("--secondary-color", themeSettings.secondaryColor);
    root.style.setProperty(
      "--text-color",
      themeMode === "light" ? themeSettings.textColor : "#ffffff"
    );
    root.style.setProperty(
      "--background-color",
      themeMode === "light" ? themeSettings.backgroundColor : "#141414"
    );
  }, [themeSettings, themeMode]);

  return (
    <LayoutContext.Provider value={{ themeMode }}>
      <ConfigProvider theme={getAntdTheme()}>{children}</ConfigProvider>
    </LayoutContext.Provider>
  );
};
