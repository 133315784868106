import React from "react";
import { Route, Routes } from "react-router-dom";

import PublicLayout from "../../layout/components/PublicLayout";
import PrivateLayout from "../../layout/components/PrivateLayout";
import ProtectedRoute from "./ProtectedRoute";

import Login from "../components/Login";
import Register from "../components/Register";
import VerifyEmail from "../components/VerifyEmail";
import Activate from "../components/Activate";
import PasswordResetRequest from "../components/PasswordResetRequest";
import PasswordResetConfirm from "../components/PasswordResetConfirm";
import UsernameResetRequest from "../components/UsernameResetRequest";
import UsernameResetConfirm from "../components/UsernameResetConfirm";
import Profile from "../components/Profile";
import ChangePassword from "../components/ChangePassword";
import GoogleCallback from "../components/GoogleCallback";

const AuthRouter = () => {
  return (
    <Routes>
      {/* Public routes */}
      <Route element={<PublicLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="verify-email" element={<VerifyEmail />} />
        <Route path="activate/:uid/:token" element={<Activate />} />
        <Route path="password-reset" element={<PasswordResetRequest />} />
        <Route
          path="password-reset/confirm/:uid/:token"
          element={<PasswordResetConfirm />}
        />
        <Route path="username-reset" element={<UsernameResetRequest />} />
        <Route
          path="username-reset/confirm/:uid/:token"
          element={<UsernameResetConfirm />}
        />
        <Route path="callback/google" element={<GoogleCallback />} />
      </Route>

      {/* Protected routes */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <PrivateLayout />
          </ProtectedRoute>
        }
      >
        <Route path="profile" element={<Profile />} />
        <Route path="change-password" element={<ChangePassword />} />
      </Route>

      {/* Add more account-related routes here */}
    </Routes>
  );
};

export default AuthRouter;
