import React, { useMemo } from "react";
import { Tabs } from "antd";
import { useSelector } from "react-redux";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import SetPassword from "./SetPassword";
import LinkedSocials from "./LinkedSocials";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const { t } = useTranslation("accounts");
  const user = useSelector((state) => state.auth.user);

  const items = useMemo(() => {
    const hasPassword = user?.has_password;

    return [
      {
        key: "1",
        label: t("profile.tab.edit_profile"),
        children: <EditProfile />,
      },
      {
        key: "2",
        label: hasPassword
          ? t("profile.tab.change_password")
          : t("profile.tab.set_password"),
        children: hasPassword ? <ChangePassword /> : <SetPassword />,
      },
      {
        key: "3",
        label: t("profile.tab.linked_socials"),
        children: <LinkedSocials />,
      },
    ];
  }, [user?.has_password, t]);

  return (
    <div>
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default Profile;
