import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, Button, Space, Form, message } from "antd";
import { ColorPicker } from "antd";
import { fetchTeam, editTeam } from "../actions/teamsActions";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const TeamThemeTab = ({ team }) => {
  const { t } = useTranslation("teams"); // Initialize translation with the 'teams' namespace
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (team.id && !team) {
      // Fetch team data if not already loaded
      dispatch(fetchTeam(team.id));
    }
  }, [dispatch, team.id, team]);

  useEffect(() => {
    if (team) {
      form.setFieldsValue({
        primary_color: team.primary_color || null,
        secondary_color: team.secondary_color || null,
      });
    }
  }, [team, form]);

  const handleUpdateTheme = async (values) => {
    setLoading(true);
    try {
      await dispatch(editTeam(team.id, values));
      message.success(t("team-theme.success.update"));
    } catch (error) {
      message.error(
        t("team-theme.error.update", { error: error.response.data.detail })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleResetTheme = async () => {
    setLoading(true);
    try {
      const resetValues = {
        primary_color: null,
        secondary_color: null,
      };
      await dispatch(editTeam(team.id, resetValues));
      form.setFieldsValue({
        primary_color: null,
        secondary_color: null,
      });
      message.success(t("team-theme.success.reset"));
    } catch (error) {
      message.error(t("team-theme.error.reset"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Space direction="vertical" size="middle" style={{ display: "flex" }}>
      <Card title={t("team-theme.title")}>
        <Form form={form} layout="vertical" onFinish={handleUpdateTheme}>
          <Form.Item
            label={t("team-theme.label.primary_color")}
            name="primary_color"
          >
            <ColorPicker
              value={form.getFieldValue("primary_color")}
              onChange={(color) =>
                form.setFieldValue("primary_color", color.toHexString())
              }
              disabledAlpha
            />
          </Form.Item>
          <Form.Item
            label={t("team-theme.label.secondary_color")}
            name="secondary_color"
          >
            <ColorPicker
              value={form.getFieldValue("secondary_color")}
              onChange={(color) =>
                form.setFieldValue("secondary_color", color.toHexString())
              }
              disabledAlpha
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              style={{ marginRight: "8px", marginBottom: "8px" }}
              htmlType="submit"
              loading={loading}
            >
              {t("team-theme.button.update")}
            </Button>
            <Button
              type="default"
              style={{ marginBottom: "8px" }}
              onClick={handleResetTheme}
              loading={loading}
            >
              {t("team-theme.button.reset")}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Space>
  );
};

export default TeamThemeTab;
