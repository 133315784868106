import React from "react";
import { Avatar } from "antd";

const TeamAvatar = ({ image, name, primaryColor, size = 128 }) => {
  return (
    <Avatar
      size={size}
      src={image || null}
      style={{
        backgroundColor: !image ? primaryColor : "transparent",
        border: "0",
      }}
    >
      {!image && name.charAt(0).toUpperCase()}{" "}
    </Avatar>
  );
};

export default TeamAvatar;
